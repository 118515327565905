import { useTranslate, IResourceComponentsProps } from '@pankod/refine-core'
import { Order, Supplement } from 'interfaces'
import { capitalizeFirstLetter } from 'utility/capitalizeFirstLetter'
import { OrdersLiveList } from './ordersLiveList'
import '../../custom.css'
import { ADMITTED_STATUS, CANCELLED_STATUS, DECREASE_RETURN_STATUS, DELIVERED_STATUS, INTERNAL_SPENDING_RETURN_STATUS, NO_STOCK_STATUS, ORDER_STATUS_ID_DENIED, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_REQUESTED, PARCIAL_RETURN_STATUS, PENALIZATION_STATUS, PREPARED_STATUS, PROCESSED_STATUS, RETURN_STATUS, SHIPPED_STATUS, SUPPLEMENT_STATUS, formatDateTime, itemsCompletedCounter } from 'utility'
import { Tabs } from '@pankod/refine-antd'
import { useState } from 'react'

export type OrderLiveListProps = {
  select: string
  dataToExport: (orders: any[]) => any
  getConditionalClassName: (record: Order) => string
  onlyPaidOrders: boolean
  statusOrder: Number | Number[] | null
  specialFilter?: string | null
}

export const OrderLiveList: React.FC<IResourceComponentsProps> = () => {
  const select = 'id, estimated_costs_status, alias, total, is_priority, completed, created_at, estimated_delivered_date, package_number, location, cancelation_date, initial_payment, return_date, locked, locked_by, status_id, sequra_disbursement_ref, status(id, name), marketplace_id(id, name), supplements(supplement, volumetric, description, status(id, name))'

  const t = useTranslate()

  const [tab, setTab] = useState(localStorage.getItem('lastOrderLiveListTab') || '1')

  const getConditionalClassName = (record: Order) => {
    if (record.cancelation_date && (record.status_id !== CANCELLED_STATUS && record.status_id !== PENALIZATION_STATUS && record.status_id !== NO_STOCK_STATUS)) {
      return 'cancelled-order'
    }
    if (record.return_date && record.status_id !== RETURN_STATUS && record.status_id !== PARCIAL_RETURN_STATUS && record.status_id !== DECREASE_RETURN_STATUS && record.status_id !== INTERNAL_SPENDING_RETURN_STATUS) {
      return 'return-order'
    }
    return ''
  }

  const handleClick = (key: string, e: any) => {
    e.preventDefault()
    setTab(key)
    localStorage.setItem('lastOrderLiveListTab', key)
    localStorage.removeItem('ordersPage')
  }

  const dataToExport = (orders: any[]) => {
    return orders?.map((order: any) => {
      const marketplace = order?.marketplace_id?.id
        ? order?.marketplace_id?.name
        : (order?.items && order?.items[0]?.custom_order_site) ? order?.items[0]?.custom_order_site : (order?.marketplace_id?.name || '')

      const supplementAmount = (order.supplements && order.supplements.length > 0)
        ? order?.supplements
          .map((s: Supplement) => s.supplement || 0)
          .reduce((a: number, b: number) => a + b, 0)
        : 0

      let data = {
        [t('order.fields.id')]: order?.id || '',
        [t('order.fields.createdAt')]: formatDateTime(order?.created_at, true) || '',
        [t('order.fields.alias')]: order?.alias || '',
        [t('order.fields.package_number')]: order?.package_number || '',
        [t('order.fields.location')]: order?.location || '',
        [t('order.fields.marketplace')]: marketplace || '',
        [t('order.fields.status')]: capitalizeFirstLetter(order?.status?.name) || '',
        [t('order.fields.supplementShipment')]: supplementAmount || 0
      }
      if (tab === '13') {
        data[t('order.fields.initial_payment')] = order?.initial_payment || 0
        data[t('order.fields.total')] = order?.total || 0
        data[t('order.fields.disbursement')] = order?.sequra_disbursement_ref || ''
        delete data[t('order.fields.package_number')]
        delete data[t('order.fields.location')]
        delete data[t('order.fields.supplementShipment')]
      }

      if (tab === '11') {
        const refundsSupplier = (order.refunds && order.refunds.length > 0)
          ? order?.refunds
            .filter((r: any) => r.type === 'supplier')
            .map((r: any) => r.amount || 0)
            .reduce((a: number, b: number) => a + b, 0)
          : 0
        data = {
          ...data,
          [t('order.fields.refundsSupplier')]: refundsSupplier || 0
        }
      } else if (tab === '10' || tab === '13') {
        console.log(order)
        const refundsClient = (order.refunds && order.refunds.length > 0)
          ? order?.refunds
            .filter((r: any) => r.type !== 'supplier')
            .map((r: any) => r.amount || 0)
            .reduce((a: number, b: number) => a + b, 0)
          : 0
        data = {
          ...data,
          [t('order.fields.refundsClient')]: refundsClient || 0
        }
      }

      if (tab === '9') {
        data = {
          ...data,
          [t('order.fields.itemsCompleted')]: itemsCompletedCounter(order?.items),
          [t('order.fields.completed')]: order?.completed
        }
      }

      return data
    })
  }

  return (

    <Tabs defaultActiveKey='1' activeKey={tab} size='middle' onTabClick={handleClick}>
      <Tabs.TabPane tab={t('order.orders')} key='1'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={true}
          statusOrder={null}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.requestedOrders')} key='2'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={ORDER_STATUS_ID_REQUESTED}
          specialFilter={'requested'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.cancelRequest')} key='3'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={null}
          specialFilter={'cancel'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.returnRequest')} key='4'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={[ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS, DELIVERED_STATUS]}
          specialFilter={'return'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.supPendingPay')} key='5'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={SUPPLEMENT_STATUS}
          specialFilter={'sup_pending_pay'}
      />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.supPendingPayNoVol')} key='6'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={SUPPLEMENT_STATUS}
          specialFilter={'sup_pending_pay_no_volumetric'}
      />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.budgeted')} key='7'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={-1}
          specialFilter={'budgeted'}
      />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.supPendingPayVol')} key='8'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={SUPPLEMENT_STATUS}
          specialFilter={'sup_pending_pay_volumetric'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.desparedOrders')} key='9'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={PROCESSED_STATUS}
          specialFilter={'despared'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.estimated')} key='12'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={[PROCESSED_STATUS]}
          specialFilter={'estimated'}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('order.refundsOrders')} key='10'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={-1}
          specialFilter={'refunds'}
        />
      </Tabs.TabPane>
      {/* <Tabs.TabPane tab={t('order.refundsOrdersProvider')} key='11'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={-1}
          specialFilter={'refunds_provider'}
        />
      </Tabs.TabPane> */}
      {/* <Tabs.TabPane tab={t('order.freeVol')} key='12'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={-1}
          specialFilter={'free_vol'}
        />
      </Tabs.TabPane> */}
      <Tabs.TabPane tab={t('order.sequra')} key='13'>
        <OrdersLiveList
          select={select}
          dataToExport={dataToExport}
          getConditionalClassName={getConditionalClassName}
          onlyPaidOrders={false}
          statusOrder={[ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, SHIPPED_STATUS, DELIVERED_STATUS, ORDER_STATUS_ID_ON_HOLD, ORDER_STATUS_ID_DENIED, RETURN_STATUS, PENALIZATION_STATUS, PARCIAL_RETURN_STATUS, DECREASE_RETURN_STATUS]}
          specialFilter={'sequra'}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}
