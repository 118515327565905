import { useTranslate } from '@pankod/refine-core'
import { Alert, Form, Modal, ModalProps, Select } from '@pankod/refine-antd'
import { definitions } from 'interfaces'
import { supabaseClient } from 'utility'
import { useState } from 'react'
import { ISSUE_LOCATION_MAPPER } from 'utility/mapper'

type ModalChangeIssuesLocationsProps = {
  modalProps: ModalProps,
  close: any,
  selectedRows: definitions['issues'][],
  getIssuesList: () => void
}

export const ModalChangeIssuesLocations: React.FC<ModalChangeIssuesLocationsProps> = ({ modalProps, close, selectedRows, getIssuesList }) => {
  const t = useTranslate()
  const [locationId, setLocationId] = useState<number>()
  const [loading, setLoading] = useState<boolean>(false)
  const [showUpdateError, setShowUpdateError] = useState<boolean>(false)

  const updateOrdersStatus = async () => {
    setLoading(true)
    setShowUpdateError(false)
    const data = selectedRows?.map((row: any) => { return { id: row.id } })

    const promiseArray = []

    const userEmail = supabaseClient.auth.user()?.email

    for (const issue of data) {
      promiseArray.push(supabaseClient.from('issues')
        .update({
          location: locationId,
          updated_by: userEmail
        })
        .eq('id', issue.id)
      )
    }

    const results = await Promise.all(promiseArray)

    let querySucess: any[] = []
    results.forEach((r) => {
      if (r.error) {
        setShowUpdateError(true)
      } else if (r.data) {
        querySucess = [...querySucess, ...r.data]
        getIssuesList()
      }
    })

    setLoading(false)

    close()
  }

  return (
    <Modal
      {...modalProps}
      title={t('issues.titles.change_location')}
      centered
      onOk={updateOrdersStatus}
      onCancel={() => {
        close()
        getIssuesList()
      }}
      confirmLoading={loading}
    >
      {`${t('issues.fields.selected_issues')}: ${selectedRows?.map((row) => row.id).join(', ')}`}
      <Form layout='vertical'>
        <Form.Item
          label={t('issues.fields.location')}
        >
          <Select
            style={{ width: 180 }}
            options={Object.entries(ISSUE_LOCATION_MAPPER).map(([key, value]) => ({
              value: parseInt(key),
              label: t(`issues.issue_location.${value}`)
            }))}
            onChange={(key) => { setLocationId(key) }}
          />
        </Form.Item>
        {showUpdateError && <Alert message={t('shipments.errors.errorUpdating')} type="warning" />}
      </Form>
    </Modal>
  )
}
