import dayjs from 'dayjs'
import { useTranslate } from '@pankod/refine-core'

import {
  Form,
  Input,
  Row,
  Col,
  Select,
  FormProps,
  Spin,
  DatePicker,
  Divider,
  Checkbox
} from '@pankod/refine-antd'
import { findAdminUsersByText, findOrdersByIdWithoutIssues, getOrderItems, sortAlphabetically } from 'utility'
import { useEffect, useState } from 'react'
import { definitions } from 'interfaces'
import { useDebouncedCallback } from 'use-debounce'
import { ISSUE_LOCATION_MAPPER, ISSUE_TYPES_MAPPER, getConfigForClientStatusType, getConfigForIssueType } from 'utility/mapper'
import { OrderSimpleInfo } from 'pages/orders'
import { IssueLocationId, IssueTypeIdTypes, SubIssueTypeIdTypes } from 'types/types'

type IssueFormProps = {
  formProps: FormProps<{}>
  isCreate: boolean
  issueTypeId?: number
  orderId?: number
  itemIds?: number[]
  setUserGmail: any
  setNote: any
}

export const IssueForm: React.FC<IssueFormProps> = ({
  formProps,
  isCreate,
  issueTypeId,
  orderId,
  itemIds,
  setUserGmail,
  setNote
}) => {
  const t = useTranslate()
  const [selectedIssueTypeId, setSelectedIssueTypeId] = useState<IssueTypeIdTypes>(formProps.initialValues?.issue_type_id)
  const [selectedSubIssueTypeId, setSelectedSubIssueTypeId] = useState<SubIssueTypeIdTypes>(formProps.initialValues?.issue_sub_type_id)
  const [location, setLocation] = useState<string>(formProps.initialValues?.location)
  const [completed, setCompleted] = useState<boolean>(formProps.initialValues?.is_completed)
  const [orders, setOrders] = useState<definitions['Order'][]>([])
  const [selectedOrder, setSelectedOrder] = useState<definitions['Order']>()
  const [itemsOptions, setItemsOptions] = useState<definitions['items'][]>([])
  const [fetching, setFetching] = useState(false)
  const [userOptions, setUserOptions] = useState<definitions['users'][]>([])
  const debouncedSearchOrders = useDebouncedCallback((value) => handleSearchOrders(value), 500)

  async function handleSearchOrders (newValue: string) {
    if (newValue.length > 0) {
      setFetching(true)
      const res = await findOrdersByIdWithoutIssues(newValue)
      if (res) {
        setOrders(res || [])
      }
      setFetching(false)
    }
  }

  const handleSearchUser = async (newValue: string) => {
    if (newValue.length > 3) {
      setFetching(true)
      const res = await findAdminUsersByText(newValue)
      if (res) {
        setUserOptions(res)
      }
      setFetching(false)
    }
  }

  async function handleSearchItems (orderId: number) {
    setFetching(true)
    const res = await getOrderItems(orderId)
    if (res) {
      setItemsOptions(res || [])
    }
    setFetching(false)
  }

  useEffect(() => {
    if (!isCreate && orderId) handleSearchItems(orderId)
  }, [])

  useEffect(() => {
    const locationVal: IssueLocationId = formProps.initialValues?.location
    formProps.form?.setFieldsValue({
      client_status_id: formProps.initialValues?.client_status_id || undefined,
      issue_status_id: formProps.initialValues?.issue_status_id || undefined,
      location: formProps.initialValues?.location || undefined
    })
    setLocation(`${locationVal}`)
  }, [formProps])

  const { issueSubTypes } = getConfigForIssueType(selectedIssueTypeId || issueTypeId)
  const { clientStatuses, issueStatuses } = getConfigForClientStatusType(selectedSubIssueTypeId || issueTypeId)

  return (
    <>
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          issue_type_id: issueTypeId,
          order_id: orderId,
          item_ids: itemIds,
          ...formProps.initialValues
        }}
      >
        <Form.Item
          label={t('issues.fields.issue_type_id')}
          name='issue_type_id'
          hidden={Boolean(isCreate && issueTypeId)}
          rules={[{ required: true }]}
        >
          <Select
            options={Object.entries(ISSUE_TYPES_MAPPER).map(([key, value]) => ({
              value: parseInt(key),
              label: t(`issues.issue_types.${value}`)
            }))}
            onChange={(value) => {
              setSelectedIssueTypeId(value)
              formProps.form?.setFieldsValue({
                issue_sub_type_id: undefined,
                client_status_id: undefined,
                issue_status_id: undefined
              })
            }}
          />
        </Form.Item>
        <Row gutter={[64, 0]} wrap>
          <Col xs={24} xl={isCreate ? 24 : 14}>
            <Form.Item
              label={t('issues.fields.order_id')}
              name="order_id"
              hidden={Boolean(!isCreate || orderId)}
              rules={[{ required: true }]}
            >
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={true}
                onSearch={debouncedSearchOrders}
                options={(orders || []).map(order => ({
                  value: order.id,
                  label: order.id
                }))}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onChange={(value) => setSelectedOrder(orders.find(order => order.id === value))}
              />
            </Form.Item>
            <Form.Item
              label={t('issues.fields.issue_sub_type_id')}
              name="issue_sub_type_id"
            >
              <Select
                options={Object.entries(issueSubTypes || [])
                  .map(([key, value]) => ({
                    value: parseInt(key),
                    label: t(`issues.issue_sub_types.${value}`)
                  }))
                  .sort((a, b) => sortAlphabetically(a.label, b.label))
                }
                onChange={(value) => {
                  formProps.form?.setFieldsValue({
                    client_status_id: undefined,
                    issue_status_id: undefined
                  })
                  setSelectedSubIssueTypeId(value)
                }}
              />
            </Form.Item>
            {!isCreate && (
              <>
                <Form.Item
                  label={t('issues.fields.item_ids')}
                  name="item_ids"
                >
                  <Select
                    mode='multiple'
                    options={(itemsOptions || []).map(item => ({
                      value: item.id,
                      label: item.name
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.client_status_id')}
                  name="client_status_id"
                >
                  <Select
                    disabled={!clientStatuses}
                    options={clientStatuses
                      ? Object.entries(clientStatuses)
                        .map(([key, value]) => ({
                          value: parseInt(key),
                          label: t(`issues.client_statuses.${value}`)
                        }))
                        .sort((a, b) => sortAlphabetically(a.label, b.label))
                      : []
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.issue_status_id')}
                  name="issue_status_id"
                >
                  <Select
                    disabled={!issueStatuses}
                    options={issueStatuses
                      ? Object.entries(issueStatuses)
                        .map(([key, value]) => ({
                          value: parseInt(key),
                          label: t(`issues.issue_statuses.${value}`)
                        }))
                        .sort((a, b) => sortAlphabetically(a.label, b.label))
                      : []
                    }
                  />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.location')}
                  name="location"
                >
                  <Select
                    key={location}
                    style={{ width: 180 }}
                    options={Object.entries(ISSUE_LOCATION_MAPPER).map(([key, value]) => ({
                      value: parseInt(key),
                      label: t(`issues.issue_location.${value}`)
                    }))}
                    onChange={(value) => {
                      formProps.form?.setFieldsValue({
                        location: value
                      })
                      setLocation(value)
                    }}
                    defaultValue={location}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item
              label={t('issues.fields.is_completed')}
              name="is_completed"
            >
              <Checkbox defaultChecked={completed} onChange={(e: any) => {
                const newVal = !completed
                setCompleted(newVal)
                formProps.form?.setFieldsValue({ is_completed: newVal })
              }} />
            </Form.Item>
          </Col>
          {!isCreate && (
            <>
              <Col xs={24} xl={8}>
                <Form.Item
                  label={t('issues.fields.shipping_method')}
                  name="shipping_method"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.shipper')}
                  name="shipper"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.tracking_id')}
                  name="tracking_id"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.issue_status')}
                  name="is_opened"
                >
                  <Select
                    options={[
                      { label: t('issues.issue_status.open'), value: true },
                      { label: t('issues.issue_status.close'), value: false }
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.created_by')}
                  name="created_by"
                >
                  <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    onSearch={handleSearchUser}
                    onChange={(value) => setUserGmail(value)}
                    options={(userOptions || []).map(u => ({
                      value: u.email
                    }))}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                  />
                </Form.Item>
              </Col>
              <Divider />
              <Col xs={24} sm={8}>
                <Form.Item
                  label={t('issues.fields.request_date')}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : ''
                  })}
                  name="request_date"
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label={t('issues.fields.issue_deadline')}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : ''
                  })}
                  name="issue_deadline"
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} sm={8}>
                <Form.Item
                  label={t('issues.fields.delivery_deadline')}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : ''
                  })}
                  name="delivery_deadline"
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Divider />
              <Col xs={24}>
                <Form.Item
                  label={t('issues.fields.reason')}
                  name="reason"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.notes')}
                >
                  <Input.TextArea onChange={(e) => { setNote(e.target.value) }} />
                </Form.Item>
                <Form.Item
                  label={t('issues.fields.notes_marketplace')}
                  name="notes_marketplace"
                >
                  <Input.TextArea />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {isCreate && issueTypeId && <OrderSimpleInfo order={selectedOrder} />}
    </>
  )
}
