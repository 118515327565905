import dayjs from 'dayjs'
import { DateField, List, Table } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { useContext, useEffect, useState } from 'react'
import { ClientStatusIdTypes, IssueSubTypeIdTypes, IssueTypeIdTypes, IssueStatusIdTypes, IssueLocationId } from 'types/types'
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT, supabaseClient } from 'utility'
import { CLIENT_STATUSES_MAPPER, ISSUE_SUB_TYPES_MAPPER, ISSUE_TYPES_MAPPER, ISSUE_STATUS_MAPPER, ISSUE_LOCATION_MAPPER } from 'utility/mapper'
import { FileWithLink } from 'components/File/FileWithLink'
import { IssuesContext } from './show'

type IssuesHistoryProps = {
  issue: definitions['issues']
  field?: keyof definitions['issues']
  onlyNotes?: boolean
}
type AuditIssues = definitions['audit_issues'] & { record: definitions['issues'] }
type AuditIssuesFiles = definitions['audit_issues_files'] & { record: definitions['issues_files'] }
type IssuesDefinitions = AuditIssues | AuditIssuesFiles
type RecordChanges = { 'old_value': any, 'new_value': any }
type ModifiedRecord = { [key: string]: RecordChanges }
type IssuesDefinitionsWithMod = IssuesDefinitions & { modifiedRecord: ModifiedRecord }
type Record = { [key: string]: any }

const EMPTY_TEXT = '---'

export const IssueHistory: React.FC<IssuesHistoryProps> = ({ issue, field, onlyNotes }) => {
  const { refetch } = useContext(IssuesContext)
  const t = useTranslate()
  const [dataSource, setDataSource] = useState<IssuesDefinitionsWithMod[]>([])

  function isAuditIssue (obj: IssuesDefinitions): obj is AuditIssues {
    return (obj as AuditIssues).order_id !== undefined
  }

  async function fetchData () {
    const { data: issuesData } = await supabaseClient
      .from<AuditIssues>('audit_issues')
      .select('*')
      .eq('resource_id', issue.id)
      .order('created_at', { ascending: false })

    const { data: issuesFilesData } = await supabaseClient
      .from<AuditIssuesFiles>('audit_issues_files')
      .select('*')
      .eq('issue_id', issue.id)
      .order('created_at', { ascending: false })

    const processedIssuesData = processChanges(transformAuditIssueData(issuesData || []))
    const processedIssuesFilesData = processChanges(issuesFilesData || [])

    const combinedData = [...processedIssuesData, ...processedIssuesFilesData]
    combinedData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())

    return combinedData
  }

  function transformAuditIssueData (data: AuditIssues[]) {
    if (!field) return data

    const auditIssues: AuditIssues[] = []
    data.forEach(audit => {
      auditIssues.push({
        ...audit,
        record: {
          [field]: audit.record[field],
          updated_by: audit.record.updated_by
        }
      } as AuditIssues)
    })

    return auditIssues
  }

  function processChanges (data: IssuesDefinitions[]) {
    const results: IssuesDefinitionsWithMod[] = []

    data.forEach((audit, index) => {
      if (audit.operation === 'UPDATE') {
        const previousAudit = data[index + 1]
        if (previousAudit) {
          const diff = findDifferences(previousAudit.record as Record, audit.record as Record)

          if (Object.keys(diff).length === 1 && Object.keys(diff)[0] === 'updated_by') return

          if (Object.keys(diff).length > 0) {
            results.push({
              ...audit,
              modifiedRecord: diff
            })
          }
        }
      }
    })

    return results
  }

  function findDifferences (prev: Record, curr: Record) {
    const diff: ModifiedRecord = {}
    Object.keys(curr).forEach(key => {
      if (onlyNotes === true) {
        if (prev[key] !== curr[key] && key === 'notes') {
          diff[key] = {
            old_value: prev[key] === undefined || prev[key] === null ? EMPTY_TEXT : prev[key],
            new_value: curr[key]
          }
        }
      } else {
        if (prev[key] !== curr[key]) {
          diff[key] = {
            old_value: prev[key] === undefined || prev[key] === null ? EMPTY_TEXT : prev[key],
            new_value: curr[key]
          }
        }
      }
    })
    return diff
  }

  function parseValue (column: string, change: any, color?: string) {
    if (!change || change === EMPTY_TEXT) return <b>{EMPTY_TEXT}</b>
    else if (column === 'issue_type_id') return t(`issues.issue_types.${ISSUE_TYPES_MAPPER[change as IssueTypeIdTypes]}`)
    else if (column === 'issue_sub_type_id') return t(`issues.issue_sub_types.${ISSUE_SUB_TYPES_MAPPER[change as IssueSubTypeIdTypes]}`)
    else if (column === 'client_status_id') return t(`issues.client_statuses.${CLIENT_STATUSES_MAPPER[change as ClientStatusIdTypes]}`)
    else if (column === 'issue_status_id') return t(`issues.issue_statuses.${ISSUE_STATUS_MAPPER[change as IssueStatusIdTypes]}`)
    else if (column === 'location') return t(`issues.issue_location.${ISSUE_LOCATION_MAPPER[change as IssueLocationId]}`)
    else if (column === 'is_opened') return t(`issues.fields.${change ? 'opened' : 'closed'}`)
    else if (dayjs(change).isValid()) return <DateField format={['delivery_deadline', 'issue_deadline'].includes(column) ? DEFAULT_DATE_FORMAT : DEFAULT_DATE_TIME_FORMAT} value={change} style={{ color }} />
    else return change
  }

  function renderHistoryChange (column: string, changes: RecordChanges) {
    return (
      <>
        {
          onlyNotes !== true && (
            <>
              <span style={{ color: 'red', wordBreak: 'break-word' }}>{parseValue(column, changes.old_value, 'red')}</span>
              <b>{' | '}</b>
            </>
          )
        }
        <span style={{ color: 'green', wordBreak: 'break-word' }}>{parseValue(column, changes.new_value, 'green')}</span>
      </>
    )
  }

  async function loadData () {
    const response = await fetchData()
    setDataSource(response)
  }

  useEffect(() => {
    loadData()
  }, [])

  useEffect(() => {
    if (refetch) loadData()
  }, [refetch])

  return (
    <List
      title={onlyNotes !== true ? `${t('issues.titles.history')}${field ? ` - ${t(`issues.fields.${field}`)}` : ''}` : ''}
      breadcrumb={false}
    >
      <Table
        dataSource={dataSource}
        rowKey={(row) => isAuditIssue(row) ? `audit_issues-${row.id}` : `audit_issues_files-${row.id}`}
        scroll={{ x: '100%', y: '350px' }}
      >
        <Table.Column
          key="modifiedRecord"
          dataIndex="modifiedRecord"
          title={t('issues.fields.changes')}
          render={(modifiedRecord, audit: IssuesDefinitionsWithMod, index) => {
            if (!isAuditIssue(audit)) {
              return (
                <div style={{ display: 'flex' }}>
                  <FileWithLink href={audit.record.public_url} />
                  <div style={{ marginLeft: 10, alignContent: 'center' }}>
                    <div>
                      <b>{t('issues_files.fields.file_name')}</b>
                      {': '}
                      {audit.record.file_name}
                    </div>
                    <div>
                      <b>{t('issues_files.fields.deleted_by')}</b>
                      {': '}
                      {audit.record.deleted_by}
                    </div>
                  </div>
                </div>
              )
            }
            return <>
              {Object.entries(modifiedRecord).map(([key, changes]) => {
                if (['deleted', 'updated_by'].includes(key)) return null
                return <div key={`${index}-${key}`}>
                  <b>{t(`issues.fields.${key}`)}</b>
                  {': '}
                  {renderHistoryChange(key, changes as RecordChanges)}
                </div>
              })}
            </>
          }}
        />
        <Table.Column
          key="created_at"
          dataIndex="created_at"
          title={onlyNotes === true ? t('issues.fields.date') : t('issues.fields.created_at')}
          align="center"
          render={(_, audit: IssuesDefinitionsWithMod) => {
            if (isAuditIssue(audit) && onlyNotes) return <><DateField format={DEFAULT_DATE_TIME_FORMAT} value={audit.created_at}/> <p>{audit.record.updated_by}</p></>
            return <DateField format={DEFAULT_DATE_TIME_FORMAT} value={audit.created_at} />
          }}
          sorter
          className='content-start'
        />
        {
          onlyNotes !== true
            ? <Table.Column
            key="updated_by"
            dataIndex="updated_by"
            title={t('issues.fields.updated_by')}
            align="center"
            render={(_, audit: IssuesDefinitionsWithMod) => {
              if (isAuditIssue(audit)) return audit.record.updated_by
              else return audit.record.deleted_by
            }}
            className='content-start'
          />
            : null
        }
      </Table>
    </List>
  )
}
