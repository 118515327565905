import { Form, FormProps, RcFile, Upload } from '@pankod/refine-antd'
import { useNotification, useTranslate } from '@pankod/refine-core'
import { definitions } from 'interfaces'
import { getRandomString, normalizeFile, supabaseClient } from 'utility'

type IssuesFilesUploadFormProps = {
  formProps: FormProps<{}>
  refetch: () => void
  issue: definitions['issues']
}

export const IssuesFilesUploadForm: React.FC<IssuesFilesUploadFormProps> = ({
  formProps,
  refetch,
  issue
}) => {
  const t = useTranslate()
  const { open } = useNotification()

  return (
    <Form
      {...formProps}
      layout="vertical"
      initialValues={{
        isActive: true,
        ...formProps.initialValues
      }}
      id="issues_files-form"
    >
      <Form.Item
        name="files"
        valuePropName="fileList"
        normalize={normalizeFile}
        noStyle
      >
        <Upload.Dragger
          listType='picture-card'
          customRequest={async ({
            file,
            onError,
            onSuccess
          }) => {
            const rcFile = file as RcFile
            const fileName = `${getRandomString()}-${rcFile.name}`
            const fileUrl = `${issue.id}/${fileName}`

            const { error } = await supabaseClient
              .storage
              .from('issues')
              .upload(fileUrl, file, {
                cacheControl: '3600',
                upsert: true
              })

            if (error) {
              return onError?.(error)
            }

            const { data: uploadedData, error: urlError } = await supabaseClient.storage
              .from('issues')
              .getPublicUrl(fileUrl)

            if (urlError) {
              return onError?.(urlError)
            }

            const { error: insertError } =
              await supabaseClient
                .from('issues_files')
                .insert({ issue_id: issue.id, file_name: fileName, public_url: uploadedData?.publicURL })

            if (insertError) {
              return open?.({
                type: 'error',
                message: t('notifications.errorMsg')
              })
            }

            const { signedURL } = await supabaseClient.storage
              .from('issues')
              .createSignedUrl(fileUrl, 60)

            refetch()

            onSuccess?.(
              { url: signedURL },
              new XMLHttpRequest()
            )
          }}
        >
          <p className="ant-upload">
            {t('actions.dragFile')}
          </p>
        </Upload.Dragger>
      </Form.Item>
    </Form>
  )
}
