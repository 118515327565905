import { Badge, useModal } from '@pankod/refine-antd'
import { ClockInModal } from './ClockInModal'
import { useEffect, useState } from 'react'
import { getAllEntryDatas, getLastEntryData } from 'utility'
import dayjs, { Dayjs } from 'dayjs'
import CustomCalendar from './CustomCalendar'

export const ClockInCalendar = ({ user, EmployeeTimesEditableTable }: any) => {
  const { modalProps, show, close } = useModal()
  const [selectedDate, setSelectedDate] = useState<any>('0000-00-00')
  const [allTimes, setAllTimes] = useState<any>()

  const getEmployeeLastTime = async () => {
    if (user?.id) {
      const data = await getLastEntryData(user?.id)
      if (data) {
        setSelectedDate(dayjs(dayjs(data.date_start).format('YYYY-MM-DD')))
      }
    }
  }

  const getEmployeeAllTimes = async () => {
    if (user?.id) {
      const allData = await getAllEntryDatas(user?.id)
      if (allData) {
        const allStartDates = allData.map(data => data?.date_start.split('T')[0])
        setAllTimes(allStartDates)
      }
    }
  }

  useEffect(() => {
    getEmployeeLastTime()
    getEmployeeAllTimes()
  }, [user])

  const dateCellRender = (value: Dayjs) => {
    const fechaStr = value?.format('YYYY-MM-DD')

    if (allTimes?.includes(fechaStr)) {
      return <Badge size='default' color="green"/>
    }

    return <Badge size='default' color="red"/>
  }

  const disabledDate = (current: any) => {
    if (selectedDate) {
      return current.isBefore(selectedDate, 'day')
    }
  }

  const showModal = (e: any) => {
    show()
    setSelectedDate(e)
  }

  return <div>
      <CustomCalendar dateCellRender={dateCellRender} style={{ marginTop: 33 }} mode={'month'} disabledDate={disabledDate} onSelect={(e: any) => { showModal(e) }}/>
      <ClockInModal modalProps={modalProps} close={close} selectedDate={selectedDate} setSelectedDate={setSelectedDate} user={user} EmployeeTimesEditableTable={EmployeeTimesEditableTable}/>
    </div>
}
