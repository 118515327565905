import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generateCalendar from 'antd/lib/calendar/generateCalendar'

// Make changes here for the locale config
dayjsGenerateConfig.locale.getWeekFirstDay = () => 1

const CustomCalendar = generateCalendar<Dayjs>(dayjsGenerateConfig)

export default CustomCalendar
