import { Skeleton } from '@pankod/refine-antd'
import { ReactNode } from 'react'

type CustomSkeletonProps = {
  loading: boolean
  children: ReactNode
}

export default function CustomSkeleton ({ loading, children }: CustomSkeletonProps) {
  return <>{loading
    ? children
    : <div>
        <Skeleton key={`skeleton${Math.random()}`} />
      </div>}</>
}
