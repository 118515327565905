import { FilterDropdown, FilterDropdownProps, Icons, Input, List, ShowButton, Table, useTable } from '@pankod/refine-antd'
import { CrudFilter, CrudFilters, IResourceComponentsProps, useTranslate } from '@pankod/refine-core'
import { InputRef } from 'antd'
import { InvoicesMatchingsModal } from './InvoicesMatchingsModal'
import { PendingMarketOrders } from 'interfaces'
import { SyncInvoicesButton } from './SyncInvoicesButton'
import { SelectFilterDropdown } from 'components/filterDropdown'
import { Dispatch, Key, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import { EventSourceButton } from 'components/EventSourceButton/EventSourceButton'
import { InvoicesMatchingsContext } from 'utility/contexts'

type Props = {
  selectedRows: PendingMarketOrders[]
  setSelectedRows: Dispatch<SetStateAction<PendingMarketOrders[]>>
}

export const InvoicesMatchingsTable: React.FC<IResourceComponentsProps & Props> = ({ selectedRows, setSelectedRows }) => {
  const t = useTranslate()
  const invalidateAll = useContext(InvoicesMatchingsContext)
  const purchaseIdRef = useRef<InputRef>(null)
  const [manualLoading, setManualLoading] = useState(false)

  const { tableProps, filters, setFilters, tableQueryResult } = useTable<PendingMarketOrders>({
    resource: 'pending_market_orders',
    permanentFilter: [
      {
        field: 'invoices_matchings.deleted',
        operator: 'eq',
        value: false
      }
    ],
    metaData: {
      select: 'market_order_id, orders(id, items(*)), purchase_id, tracking_id, purchase_amount, iva_type, iva_amount, marketplace, matches_count, has_matching_duplicates, invoices_matchings(id,iva_type)'
    }
  })

  useEffect(() => {
    if (tableQueryResult.isFetching) {
      setManualLoading(true)
    } else {
      setManualLoading(false)
    }
  }, [tableQueryResult.isFetching])

  function getRowColor (record: PendingMarketOrders) {
    const { matches_count: _matchesCount, has_matching_duplicates: hasMatchingDuplicates } = record
    const matchesCount = _matchesCount || 0

    if (matchesCount > 1) return 'error-row'
    else if (hasMatchingDuplicates) return 'duplicates-row'
    else if (matchesCount === 1) return 'warning-row'
    return ''
  }

  return (
    <List
      headerProps={{
        extra: <>
          <EventSourceButton
            endpoint="/assign_multiple_invoices"
            buttonText={t('invoices_matchings.buttons.assign_selected')}
            headerText={t('invoices_matchings.assigning_invoices')}
            completedText={t('invoices_matchings.assign_completed')}
            errorDescText={t('invoices_matchings.assign_multiple_invoices_error')}
            refetchFn={invalidateAll}
            icon={<Icons.LinkOutlined />}
            disabledCondition={!selectedRows.length}
            queryParams={{ ids: selectedRows.map(pmo => pmo.invoices_matchings[0].id).join(',') }}
          />
          <SyncInvoicesButton />
        </>
      }}
      title={t('invoices_matchings.titles.orders')}
    >
      <Table
        {...tableProps}
        rowKey="market_order_id"
        rowClassName={(record) => getRowColor(record)}
        loading={manualLoading || tableProps.loading}
        rowSelection={{
          type: 'checkbox',
          onChange: (
            _selectedRowKeys: Key[],
            selectedRows: PendingMarketOrders[]
          ) => {
            setSelectedRows(selectedRows)
          },
          renderCell: (_value, record, _index, originNode) => record.matches_count === 1 && !record.has_matching_duplicates ? originNode : null,
          getCheckboxProps: (record) => ({ disabled: record.matches_count !== 1 || record.has_matching_duplicates }),
          selectedRowKeys: selectedRows.map(pmo => pmo.market_order_id!)
        }}
      >
        <Table.Column
          key="purchase_id"
          dataIndex="purchase_id"
          title={t('invoices_matchings.fields.purchase_id')}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
              confirm={() => {
                const newFilter: CrudFilter = {
                  field: 'purchase_id',
                  operator: 'contains',
                  value: purchaseIdRef?.current?.input?.value
                }

                const _filters: CrudFilters = filters || []

                let index = _filters.findIndex((el: any) => el.field === newFilter.field)
                index = index === -1 ? _filters?.length || 0 : index
                _filters[index] = newFilter

                setFilters(_filters)

                props.confirm()
              }}
            >
              <Input ref={purchaseIdRef} placeholder={t('invoices_matchings.fields.purchase_id')} />
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="order_id"
          dataIndex={['orders', 'id']}
          title={t('invoices_matchings.fields.order_id')}
          align="center"
          sorter
          render={(value) => value && (
            <ShowButton
              resourceNameOrRouteName='orders'
              recordItemId={value}
            >
              {`#${value}`}
            </ShowButton>
          )}
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
            >
              <Input placeholder={t('invoices_matchings.fields.order_id')} />
            </FilterDropdown>
          )}
        />
        <Table.Column
          key="marketplace"
          dataIndex="marketplace"
          title={t('invoices_matchings.fields.marketplace')}
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <SelectFilterDropdown
                props={{
                  ...props,
                  clearFilters: () => {
                    props.clearFilters?.()
                    props.confirm()
                  }
                }}
                type='string'
                entity='pending_market_orders'
                optionLabel='marketplace'
                optionValue='marketplace'
                sorters={[{
                  field: 'marketplace',
                  order: 'asc'
                }]}
              />
          )}
        />
        <Table.Column<PendingMarketOrders>
          key="iva_type"
          dataIndex="iva_type"
          title={t('invoices_matchings.fields.iva_type')}
          align="center"
          sorter
          render={(_, record) => {
            if (record.invoices_matchings.length === 1) {
              return record.invoices_matchings[0]?.iva_type
            } else {
              return '-'
            }
          }}
        />
        <Table.Column
          key="matches_count"
          dataIndex="matches_count"
          title={t('invoices_matchings.fields.matches_count')}
          align="center"
          sorter
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown
              {...props}
              clearFilters={() => {
                props.clearFilters?.()
                props.confirm()
              }}
            >
              <Input type='number' min={0} placeholder={t('invoices_matchings.fields.matches_count')} />
            </FilterDropdown>
          )}
        />
        <Table.Column<PendingMarketOrders>
          fixed="right"
          title={t('table.actions')}
          dataIndex="actions"
          key="actions"
          align="center"
          render={(_, record) => {
            return <InvoicesMatchingsModal
              record={record}
              invoicesMatchingsCount={record.invoices_matchings.length}
            />
          }}
        />
      </Table>
    </List>
  )
}
