import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Grid,
  Create,
  Typography,
  Row,
  Col,
  DatePicker,
  Radio,
  Divider,
  Upload,
  RcFile,
  useModal,
  Select
} from '@pankod/refine-antd'
import { normalizeFile, PAYMENT_TYPE_BIZUM, PAYMENT_TYPE_CARD, PAYMENT_TYPE_PAYPAL, PAYMENT_TYPE_TRANFER, PAYMENT_TYPE_CARDABANCAFER, PAYMENT_TYPE_CARDABANCAOCTA, PAYMENT_TYPE_CARDBBVAFER, PAYMENT_TYPE_CARDSANTPA, supabaseClient } from 'utility'
import dayjs from 'dayjs'
import { SupplierFinder } from './supplierFinder'
import { useState } from 'react'
import { InvoicesForm } from './invoicesForm'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'

type CreateMarketOrderProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  closeDrawer: any
  tableQueryResult: any
  defaultMarketplace: any
  order: any
  invoiceNumber?: number
  expectedMarketOrderPrice: number
  marketplaceShipmentPrice: number
};

export const CreateMarketOrder: React.FC<CreateMarketOrderProps> = ({
  drawerProps,
  formProps,
  closeDrawer,
  tableQueryResult,
  defaultMarketplace,
  order,
  invoiceNumber,
  expectedMarketOrderPrice,
  marketplaceShipmentPrice
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const { modalProps, show, close } = useModal()

  const updateOrderAfterInsert = async () => {
    const statusId = order?.status_id
    const prevStatusId = order?.prev_status_id
    const orderId = order?.id

    if (statusId > -1 && prevStatusId > -1 && orderId) {
      if (statusId < 2 || prevStatusId < 2 || statusId === 5 || prevStatusId === 5) {
        if (statusId !== 11) {
          const { error: errorOrder } = await supabaseClient
            .from('orders')
            .update({
              status_id: 2,
              prev_status_id: statusId
            })
            .eq('id', orderId)

          if (errorOrder) {
            console.log(errorOrder)
          }
        }
      }
    }
  }

  const updateMarketplaceData = (item: any) => {
    const fData = { ...formData }
    if (fData.form) {
      fData.form.setFields([
        {
          name: ['marketplace_cif'],
          value: item.cif
        },
        {
          name: ['marketplace_legal_name'],
          value: item.legal_name
        },
        {
          name: ['marketplace_legal_address'],
          value: item.legal_address
        },
        {
          name: ['marketplace_province'],
          value: item.province
        },
        {
          name: ['marketplace_postal_code'],
          value: item.postal_code
        }
      ])

      setFormData({ ...fData })
    }
  }

  const [formData, setFormData] = useState({ ...formProps })
  const [invoices, setInvoices] = useState<any>(Array.from(new Array(invoiceNumber)))
  const [paymentType, setPaymentType] = useState(formProps?.initialValues?.payment_method_id || 1)

  const [validationErrorPurchaseId, setValidationErrorPurchaseId] = useState(false)

  const SaveSupplier = async () => {
    if (formData?.form?.getFieldValue('marketplace_cif')) {
      const { data: suppliers } = await supabaseClient
        .from('suppliers')
        .select('cif')
        .eq('cif', formData?.form?.getFieldValue('marketplace_cif'))

      if (suppliers && suppliers.length === 0) {
        const supplier = {
          cif: formData?.form?.getFieldValue('marketplace_cif'),
          legal_name: formData?.form?.getFieldValue('marketplace_legal_name'),
          legal_address: formData?.form?.getFieldValue('marketplace_legal_address'),
          country: 'Spain',
          province: formData?.form?.getFieldValue('marketplace_province'),
          postal_code: formData?.form?.getFieldValue('marketplace_postal_code')
        }
        await supabaseClient.from('suppliers').insert(supplier)
      }
    }
  }

  const validateData = () => {
    return (formData.form?.getFieldValue('purchase_id') !== null && formData.form?.getFieldValue('purchase_id') !== undefined && formData.form?.getFieldValue('purchase_id') !== '')
  }

  const onClickButton = async () => {
    setValidationErrorPurchaseId(false)
    if (!validateData()) {
      setValidationErrorPurchaseId(true)
      return
    }

    SaveSupplier()
    const { data: marketOrders } = await supabaseClient
      .from('market_orders')
      .select('id, purchase_id')
      .eq('order_id', order?.id)
      .eq('deleted', false)

    // if (saveButtonProps && saveButtonProps.onClick) saveButtonProps?.onClick(e)
    // Insert using invoices array
    for (const [index, value] of invoices.entries()) {
      await supabaseClient.from('market_orders').insert({
        order_id: order?.id,
        purchase_id: formData.form?.getFieldValue('purchase_id').trim(),
        transaction_id: formData.form?.getFieldValue('transaction_id')?.trim() || '',
        tracking_id: formData.form?.getFieldValue('tracking_id')?.trim() || '',
        invoice_number: formData.form?.getFieldValue('invoice_number')?.trim() || '',
        invoice_file: (index === 0) ? formData.form?.getFieldValue('invoice_file') || '' : '',
        // file_url: (index === 0) ? formData.form?.getFieldValue('file_url') || undefined : undefined,
        marketplace_legal_name: formData.form?.getFieldValue('marketplace_legal_name') || null,
        marketplace_legal_address: formData.form?.getFieldValue('marketplace_legal_address') || null,
        marketplace_cif: formData.form?.getFieldValue('marketplace_cif') || null,
        marketplace_province: formData.form?.getFieldValue('marketplace_province') || null,
        marketplace_postal_code: formData.form?.getFieldValue('marketplace_postal_code') || null,
        invoice_date: formData.form?.getFieldValue('invoice_date') || null,
        is_purchase: formData.form?.getFieldValue('is_purchase'),
        purchase_amount: value.purchase_amount,
        iva_type: value.iva_type,
        iva_amount: value.iva_amount,
        purchase_parent: (index === 0),
        updated_by: supabaseClient.auth.user()?.email,
        payment_method_id: paymentType
      })
    }

    await supabaseClient.from('orders')
      .update({
        updated_by: supabaseClient.auth.user()?.email
      })
      .eq('id', order?.id)

    if (marketOrders && marketOrders.length === 0) {
      updateOrderAfterInsert()
    }

    await tableQueryResult()
    window.location.reload()
    closeDrawer()
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '800px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={700}
    >
      <Create
        resource="market_orders"
        saveButtonProps={{
          onClick: async () => {
            const expected = expectedMarketOrderPrice + marketplaceShipmentPrice
            // eslint-disable-next-line camelcase
            const invoicePrice = invoices.map((invoice: { purchase_amount: number }) => invoice.purchase_amount || 0).reduce((acc: number, current: number) => acc + current, 0)

            if (expected < invoicePrice) show()
            else await onClickButton()
          }
        }}
        breadcrumb={false}
        goBack={false}
        title={t('order.actions.addMarketOrder')}
      >
        {Number.isInteger(formData.initialValues?.order_id) && (
          <Form {...formData} layout="vertical">
            <Form.Item
              hidden={true}
              name="order_id"
            >
              <Input />
            </Form.Item>
            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('market_order.fields.purchaseId')}
                  name="purchase_id"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('market_order.fields.invoiceNumber')}
                  name="invoice_number"
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('market_order.fields.transactionId')}
              name="transaction_id"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('market_order.fields.trackingId')}
              name="tracking_id"
            >
              <Input />
            </Form.Item>

            <Divider />

            <InvoicesForm setInvoicesToAdd={setInvoices} />

            <Row style={{ marginTop: '2rem' }} gutter={[64, 0]} wrap>
              <Col xs={24}>
                <Form.Item label={t('invoices_matchings.fields.payment_method_id')} name="payment_method_id">
                  <Select
                    defaultValue={paymentType}
                    options={[
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARD}`), value: 1 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDSANTPA}`), value: 5 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDABANCAFER}`), value: 6 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDABANCAOCTA}`), value: 7 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDBBVAFER}`), value: 8 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_BIZUM}`), value: 2 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_PAYPAL}`), value: 3 },
                      { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_TRANFER}`), value: 4 }
                    ]}
                    onChange={(e) => setPaymentType(e)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[64, 0]} wrap>
              <Col xs={24}>
                <Form.Item label="Archivos">
                  <Form.Item
                    name="invoice_file"
                    valuePropName="fileList"
                    normalize={normalizeFile}
                    noStyle
                  >
                    <Upload.Dragger
                      name="invoice_file"
                      listType='picture'
                      customRequest={async ({
                        file, onError, onSuccess
                      }) => {
                        const rcFile = file as RcFile
                        const fileUrl = `${rcFile.name}`

                        const { error } = await supabaseClient.storage
                          .from('files')
                          .upload(fileUrl, file, {
                            cacheControl: '3600',
                            upsert: true
                          })

                        if (error) {
                          return onError?.(error)
                        }
                        const { signedURL, error: urlError } = await supabaseClient.storage
                          .from('files').createSignedUrl(fileUrl, 60)

                        if (urlError) {
                          return onError?.(urlError)
                        }

                        console.log(signedURL)

                        onSuccess?.(
                          { url: signedURL },
                          new XMLHttpRequest()
                        )
                      } }
                    >
                      <p className="ant-upload-text">
                        {t('actions.dragFile')}
                      </p>
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('market_order.fields.invoiceDate')}
                  name='invoice_date'
                  initialValue={dayjs(new Date())}
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : ''
                  })}
                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  name="is_purchase"
                  label={t('market_order.fields.type')}
                  style={{ margin: 0 }}
                  initialValue={true}
                >
                  <Radio.Group>
                    <Radio value={true}>{t('market_order.fields.purchase')}</Radio>
                    <Radio value={false}>{t('market_order.fields.refund')}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Typography.Title level={4}>{t('market_order.marketplaceData')}</Typography.Title>

            <SupplierFinder updateMarketplaceData={updateMarketplaceData} />

            <Row gutter={[64, 0]} wrap>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('marketplace.fields.CIF')}
                  name='marketplace_cif'
                  initialValue={defaultMarketplace?.cif}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('marketplace.fields.legalName')}
                  name='marketplace_legal_name'
                  initialValue={defaultMarketplace?.legal_name}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('marketplace.fields.legalAddress')}
                  name='marketplace_legal_address'
                  initialValue={defaultMarketplace?.legal_address}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={t('marketplace.fields.province')}
                  name='marketplace_province'
                  initialValue={defaultMarketplace?.province}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('marketplace.fields.postalCode')}
                  name='marketplace_postal_code'
                  initialValue={defaultMarketplace?.postal_code}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {(validationErrorPurchaseId) ? <Typography.Text>{t('market_order.validation.errorValidation')}</Typography.Text> : null}
            <AdviceModal
              modalProps={modalProps}
              close={close}
              show={show}
              infoText={t('supplement.advise.purchaseLine')}
              okFunction={() => onClickButton()}
            />
          </Form>
        )}
      </Create>
    </Drawer>
  )
}
