import { useInvalidate, useList, useTranslate } from '@pankod/refine-core'
import { List, Form, Row, Col, Input, Typography, Button, Carousel, InputNumber, Modal, useModal, SaveButton, EditButton } from '@pankod/refine-antd'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'utility'
import { definitions } from 'interfaces'
import { ButtonLogicDelete } from 'components/DeleteModal/buttonLogicDelete'
import { DeleteModal } from 'components/DeleteModal/modal'

export const configurator: React.FC = () => {
  const t = useTranslate()
  const { modalProps, show, close } = useModal()
  const invalidate = useInvalidate()

  const [imgList, setImgList] = useState<any[]>([])
  const [timeTransition, setTimeTransition] = useState<number>(3)
  const [configurationName, setConfigurationName] = useState<string>()
  const [uploadingImg, setUploadingImage] = useState(false)
  const [showMsg, setShowMsg] = useState('')
  const [allImages, setAllImages] = useState<any>()
  const [selectedImages, setSelectedImages] = useState<string[]>([])
  const [currentConfig, setCurrentConfig] = useState<any>(null)
  const [currentModal, setCurrentModal] = useState<boolean>(false)
  const [newConfigurationModal, setNewConfigurationModal] = useState<boolean>(false)
  const [editId, setEditId] = useState<number>()
  const [deleteId, setDeleteId] = useState<number>()
  const [pageSize, setPageSize] = useState<number>(5)
  const [dataCount, setDataCount] = useState<number>()
  const { data } = useList<definitions['mobile_configurations']>({
    resource: 'mobile_configurations',
    metaData: {
      select: 'id, configuration_name, transition_time, images, enabled'
    },
    config: {
      sort: [
        {
          field: 'id',
          order: 'desc'
        }
      ],
      filters: [
        {
          field: 'deleted',
          operator: 'eq',
          value: false
        }
      ],
      pagination: {
        pageSize: pageSize
      }
    }
  })
  const configurations = data?.data

  const handleImg = (e: any) => {
    setImgList([...e.target.files])
  }

  const getCurrentConfig = () => {
    supabaseClient.from('variables').select('value').eq('name', 'MOBILE_CONFIG').then(({ data }: any) => {
      if (data && data[0] && data[0].value) {
        setCurrentConfig(JSON.parse(data[0].value))
      }
    })
  }

  const getCountData = async () => {
    const { count } = await supabaseClient.from('mobile_configurations')
      .select('id', { count: 'exact', head: true })
      .eq('deleted', false)
    setDataCount(Number(count))
  }

  useEffect(() => {
    getCurrentConfig()
    getAllImages()
    getCountData()
  }, [])

  useEffect(() => {
    if (currentConfig && currentConfig.timeTransition) {
      setTimeTransition(currentConfig.timeTransition)
    }
  }, [currentConfig])

  const uploadImg = async () => {
    if (configurationName === undefined || timeTransition === 0 || (!imgList.length && !selectedImages.length)) return null
    try {
      setShowMsg('')
      setUploadingImage(true)
      // Save all images and save the public url

      const urlList: string[] = []

      if (imgList && imgList.length > 0) {
        let index = 0
        for (const img of imgList) {
          const { error } = await supabaseClient.storage.from('configurator').upload(`img-mobile-${index}${img.name}`, img, { upsert: true })
          if (error) {
            throw new Error(error.message)
          } else {
            const { data, error } = await supabaseClient.storage.from('configurator').getPublicUrl(`img-mobile-${index}${img.name}`)
            urlList.push(data?.publicURL || '')

            if (error) {
              throw new Error(error.message)
            }
          }
        }
        index += 1
      }
      if (selectedImages) {
        selectedImages.map((url) => {
          urlList.push(url)
          return null
        })
      }
      if (urlList) {
        const imgData = {
          configuration_name: configurationName,
          transition_time: timeTransition,
          images: urlList
        }
        const { error } = await supabaseClient.from('mobile_configurations').insert(imgData)

        if (error) {
          throw new Error(error.message)
        }
      }

      // save in table configs -> later load config Etable on device/web and render with that config

      const jsonData = {
        timeTransition: timeTransition,
        imgList: (urlList.length > 0) ? urlList : currentConfig.imgList
      }

      const { error } = await supabaseClient.from('variables')
        .update({
          value: JSON.stringify(jsonData)
        })
        .eq('name', 'MOBILE_CONFIG')

      if (error) {
        throw new Error(error.message)
      }
      setShowMsg('OK')
      setUploadingImage(false)
      invalidate({
        resource: 'mobile_configurations',
        invalidates: ['all']
      })
      setNewConfigurationModal(false)
    } catch (error) {
      setShowMsg('KO')
      setUploadingImage(false)
    }
  }

  const getAllImages = async () => {
    const { data } = await supabaseClient.from('mobile_configurations')
      .select('images')
    const imagesArray: any = []
    data?.map((images) => {
      images.images.map((url: string) => {
        imagesArray.push(url)
        return null
      })
      return null
    })
    setAllImages(Array.from(new Set(imagesArray)))
  }

  const handleSelectImages = (url: string) => {
    if (selectedImages.find(selectedImage => selectedImage === url)) {
      setSelectedImages(selectedImages.filter(imageUrl => imageUrl !== url))
    } else {
      setSelectedImages(prev => [...prev, url])
    }
  }

  const handleEdit = async (id: number) => {
    const { error } = await supabaseClient.from('mobile_configurations')
      .update({
        configuration_name: configurationName,
        transition_time: timeTransition
      })
      .eq('id', id)
      .eq('deleted', false)
    if (error) {
      throw new Error(error.message)
    } else {
      invalidate({
        resource: 'mobile_configurations',
        invalidates: ['all']
      })
      setEditId(undefined)
    }
  }

  const applyConfiguration = async (transitionTime: number, images: string[], id: number) => {
    const { error: enabledError } = await supabaseClient.from('mobile_configurations')
      .update({ enabled: false })
      .eq('enabled', true)

    if (enabledError) {
      throw new Error(enabledError.message)
    }

    const { error: errorSelectConfig } = await supabaseClient.from('mobile_configurations')
      .update({ enabled: true })
      .eq('id', id)

    if (errorSelectConfig) {
      throw new Error(errorSelectConfig.message)
    }

    const jsonData = {
      timeTransition: transitionTime,
      imgList: (images.length > 0) ? images : currentConfig.imgList
    }
    const { error } = await supabaseClient.from('variables')
      .update({
        value: JSON.stringify(jsonData)
      })
      .eq('name', 'MOBILE_CONFIG')
    if (error) {
      throw new Error(error.message)
    } else {
      invalidate({
        resource: 'mobile_configurations',
        invalidates: ['all']
      })
      getCurrentConfig()
    }
  }

  return (
    <>
      <List title={t('menu.configurator')}>
        <Row gutter={[64, 0]} wrap>
          <Col style={{ width: '80%', height: '100%', zIndex: '10' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', marginTop: 20, alignItems: 'center' }}>
              <Button loading={uploadingImg} onClick={() => setNewConfigurationModal(true)} type='primary'>
                {t('configurator.actions.createConfiguration')}
              </Button>
              <Button loading={uploadingImg} onClick={() => setCurrentModal(true)} type='primary'>
                {t('configurator.actions.showCurrent')}
              </Button>
            </div>
            <div style={{ marginTop: '10px' }}>
              <ul>
              <Row gutter={[120, 0]}>
                <Col style={{ width: '25%', justifyContent: 'center', display: 'flex' }}><Typography>{t('table.actions')}</Typography></Col>
                <Col style={{ width: '10%', justifyContent: 'center', display: 'flex' }}><Typography>{t('configurator.id')}</Typography></Col>
                <Col style={{ width: '15%', justifyContent: 'center', display: 'flex' }}><Typography>{t('configurator.name')}</Typography></Col>
                <Col style={{ width: '15%', justifyContent: 'center', display: 'flex' }}><Typography>{t('configurator.transitionTime')}</Typography></Col>
                <Col style={{ width: '30%' }}><Typography style={{ paddingLeft: '50px' }}>{t('configurator.images')}</Typography></Col>
              </Row>
              </ul>
              <ul style={{ overflowY: 'scroll', overflowX: 'hidden', height: '600px' }}>
                {configurations?.map((config: any, index) => (
                  <Row gutter={[120, 0]} key={config.id}>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25%' }}>
                      {
                        config.id === editId
                          ? <div>
                            <SaveButton
                              hideText
                              size="small"
                              onClick={() => { handleEdit(config.id) }}
                            />
                            <Button
                              size="small"
                              style={{ marginLeft: '5px' }}
                              onClick={() => { setEditId(0) }}
                            >
                              {t('buttons.cancel')}
                            </Button>
                          </div>
                          : <div>
                            <ButtonLogicDelete
                              click={() => {
                                show()
                                setDeleteId(config.id)
                              }}
                            />
                            <EditButton
                              onClick={() => setEditId(config.id)}
                              hideText
                              size="small"
                              style={{ marginLeft: '5px' }}
                            />
                            <Button disabled={config.enabled} size="small" style={{ marginLeft: '5px' }} onClick={() => { applyConfiguration(config.transition_time, config.images, config.id) }}>{t('configurator.apply')}</Button>
                            <DeleteModal
                              modalProps={modalProps}
                              close={() => {
                                close()
                                invalidate({
                                  resource: 'mobile_configurations',
                                  invalidates: ['all']
                                })
                              }}
                              id={deleteId!}
                              entity="mobile_configurations"
                            />
                          </div>
                      }
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '10%' }} key={config.configuration_name + 'ConfigName'}>
                      <Typography>{config.id}</Typography>
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '15%' }} key={config.configuration_name + 'ConfigName'}>
                        {
                          config.id === editId
                            ? <Input defaultValue={config.configuration_name} onChange={(e) => { setConfigurationName(e.target.value) }} />
                            : <Typography>{config.configuration_name}</Typography>
                        }
                    </Col>
                    <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '15%' }} key={config.transition_time + 'transitionTime'}>
                      {
                        config.id === editId
                          ? <InputNumber defaultValue={config.transition_time} onChange={(e: number) => { setTimeTransition(e) }} />
                          : <Typography>{config.transition_time}</Typography>
                      }
                    </Col>
                    <Col style={{ width: '35%' }} key={index}>
                        {config.images.map((url: string, index: number) => (
                        <img
                          key={index}
                          style={{
                            width: '60px',
                            margin: '10px'
                          }}
                          src={`${url}`}>
                        </img>
                        ))}
                    </Col>
                  </Row>
                ))}
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {
                    dataCount && pageSize >= dataCount
                      ? null
                      : <Button onClick={() => { setPageSize(pageSize + 5) }}>{t('configurator.loadMore')}</Button>
                  }
                </div>
              </ul>
            </div>
          </Col>
          <Col style={{ width: '20%' }}>
            <Typography style={{ fontWeight: 600 }}>{t('configurator.titleView')}</Typography>
            <div style={{ height: '70%', width: '70%', display: 'flex', justifyContent: 'center' }}>

              {imgList.length > 0 && <div style={{ position: 'absolute', height: '480px', width: '320px' }}>
                <Carousel style={{ zIndex: 0, height: '480px' }} autoplay autoplaySpeed={timeTransition * 1000}>
                {imgList.map((img: any, index: number) => {
                  return (<img key={index} style={{ zIndex: 0, height: '480px' }} id='render-carousel' src={URL.createObjectURL(img)} />)
                })}
                </Carousel>
              </div>}

              <div style={{ zIndex: 1, width: '225px', height: '480px' }}>
                <img style={{ zIndex: 1, width: '225px', height: '480px' }} id='mock-up' src='images/mock_up.png' />
              </div>
            </div>
          </Col>
        </Row>
      </List>
      <Modal title={t('configurator.fields.currentConfigTitle')}
        visible={currentModal}
        centered={true}
        okType={'text'}
        cancelText={'OK'}
        okText={<></>}
        onOk={() => setCurrentModal(false)}
        onCancel={() => setCurrentModal(false)}
      >
        {(currentConfig && currentConfig.imgList.length > 0)
          ? <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Carousel style={{ zIndex: 0, width: '62%', left: '20%', position: 'absolute', height: '480px' }} autoplay autoplaySpeed={currentConfig.timeTransition * 1000}>
              {currentConfig.imgList.map((img: any, index: number) => {
                return (<img key={index} style={{ zIndex: 1, height: '480px' }} id='render-carousel' src={img + '?' + Date.now().toLocaleString()} />)
              })}
            </Carousel>

            <div style={{ zIndex: 1, width: '225px', height: '480px' }}>
              <img style={{ zIndex: 1, width: '225px', height: '480px' }} id='mock-up' src='images/mock_up.png' />
            </div>
            </div>
          : <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'absolute', height: '480px' }}>
              <img key='default-image' style={{ zIndex: 0, height: '480px' }} src={'images/img-mobile.jpg'} />
            </div>
            <div style={{ zIndex: 1, width: '225px', height: '480px' }}>
              <img style={{ zIndex: 1, width: '225px', height: '480px' }} id='mock-up' src='images/mock_up.png' />
            </div>
          </div>
          }
      </Modal>
      <Modal
        visible={newConfigurationModal}
        centered={true}
        okType={'text'}
        cancelText={'Cancel'}
        okText={t('configurator.actions.uploadImg')}
        onOk={uploadImg}
        onCancel={() => setNewConfigurationModal(false)}
      >
          <Form layout="vertical">
          <Form.Item
              label={t('configurator.name')}
              key='configuration_name'
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input onChange={(e) => setConfigurationName(e.target.value)} />
            </Form.Item>
            <Form.Item
              label={t('configurator.fields.timeTransition')}
              key='timeTransition'
              rules={[
                {
                  required: true
                }
              ]}
            >
              <InputNumber min={1} onChange={(value: any) => setTimeTransition(value)} />
            </Form.Item>

            <Form.Item
              key='imguploaderform'
              label={t('configurator.fields.uploadimg')}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Typography style={{ fontStyle: 'italic', marginBottom: 20 }}>{t('configurator.fields.sizeAdvise')}</Typography>
              <Input
                accept='.jpg, .jpeg'
                multiple={true}
                bordered={false}
                size='middle'
                type='file'
                key='imguploader'
                id='imguploader'
                style={{ display: 'none' }}
                onChange={handleImg}
              />
              <Button><label style={{ padding: '15px' }} htmlFor='imguploader'>{t('configurator.actions.uploadImage')}</label></Button>
              <div>
                {allImages?.map((url: any, index: number) => {
                  return <img
                      style={{
                        width: '65px',
                        margin: '10px',
                        border: 'solid',
                        borderColor: selectedImages.find(selectedImage => selectedImage === url) ? '#67be23' : '',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleSelectImages(url)}
                      key={index}
                      src={`${url}`}
                    />
                })
                }
              </div>
            </Form.Item>
            <Form.Item
              key='imguploader'
              label={t('configurator.selectedImages')}
            >
              <div>
                {imgList?.map((img: any, index: number) => {
                  return <img
                    style={{
                      width: '65px',
                      margin: '10px',
                      cursor: 'pointer'
                    }}
                    key={index}
                    src={URL.createObjectURL(img)}
                    onClick={() => setImgList(imgList.filter(image => image?.name !== img.name))
                    }
                  />
                })
                }
                {selectedImages.map((url, index) => {
                  return <img
                    style={{
                      width: '65px',
                      margin: '10px',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleSelectImages(url)}
                    key={index}
                    src={`${url}`}
                  />
                })}
              </div>
            </Form.Item>
          </Form>
            {(showMsg !== '') && <Typography style={{ fontWeight: 600 }}>{t(`configurator.img${showMsg}`)}</Typography>}
      </Modal>
    </>
  )
}
