/* eslint-disable eqeqeq */
import { useTranslate } from '@pankod/refine-core'

import {
  List,
  Table,
  Tag,
  DateField,
  ShowButton,
  FilterDropdownProps,
  FilterDropdown,
  Input,
  Icons,
  Space,
  Checkbox,
  useModal
} from '@pankod/refine-antd'

import { Order } from 'interfaces'
import { DEFAULT_DATE_TIME_FORMAT, ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS } from 'utility/constants'
import { Key, useEffect, useState } from 'react'
import { DateFilterDropdown, QuantityFilterDropdown, SelectFilterDropdown } from 'components/filterDropdown'
import { getOrders, supabaseClient, capitalizeFirstLetter, currency, getColorLabel, filter, sorter, getLastAuditOrderWithProcessedStatus, getOrderExport } from 'utility'
import '../../custom.css'
import { ExportToExcelButton } from 'components/ExportToExcelButton/ExportToExcelButton'
import { currentUserEmail } from 'utility/authLocal'
import { EditableRowField } from 'components/EditableRowField/EditableRowField'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'

type CheckboxWithAdviseProps = {
  record: any
}

export const CheckboxWithAdvise: React.FC<CheckboxWithAdviseProps> = ({ record }) => {
  const t = useTranslate()
  const { modalProps, show, close } = useModal()

  const [recordCompleted, setRecordCompleted] = useState(record.completed)

  const handleCompletedChange = async (value: any, record: any) => {
    if (record.id) {
      await supabaseClient.from('orders')
        .update({
          completed: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)

      setRecordCompleted(value)
    }
  }
  return (
    <>
      <Checkbox checked={recordCompleted} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        show()
      }} onChange={() => null} />
      <AdviceModal
        infoText={(!recordCompleted) ? t('order.actions.adviseToMarkAsCompleted', { order_id: record.id }) : t('order.actions.adviseToMarkAsIncompleted', { order_id: record.id })}
        okFunction={() => handleCompletedChange(!recordCompleted, record)}
        close={close}
        show={show}
        modalProps={modalProps} />
    </>
  )
}
export type tablePropsProps = {
  dataSource: any[]
}

export const RefundOrderProviderTable: React.FC<any> = ({ select, dataToExport, getConditionalClassName, issueTypeId }) => {
  let callingServer = false
  const prepareInitialFilter = () => {
    let filt = [{ field: 'supplements.deleted', operator: 'eq', value: false }]

    filt = [...filt.filter((x: filter) => x.field !== 'supplements.deleted')]
    filt.push({ field: 'status_id', operator: 'gte', value: PROCESSED_STATUS } as filter)

    return filt
  }

  const [tableProps, setTableProps] = useState<tablePropsProps>({ dataSource: [] })
  const [filters, setFilters] = useState<filter[]>(prepareInitialFilter())
  const [sorter, setSorter] = useState<sorter>({ field: 'id', ascending: false })

  const getOrdersList = async (resetPages: boolean = false) => {
    if (!callingServer) {
      setLoading(true)
      let cPage = (resetPages) ? 1 : currentPage
      let cPageSize = pageSize
      callingServer = true
      let specialFilt = null
      // Special case, we need force to query more element and filter manually
      cPage = 1
      cPageSize = 10000
      select = select.replace(', supplements(supplement, volumetric, description, status(id, name))', ', refunds!inner(type, status_id(id, name), deleted, amount))')
      specialFilt = [...filters]
      specialFilt.push({ field: 'refunds.type', operator: 'eq', value: 'supplier' } as filter)
      specialFilt.push({ field: 'refunds.deleted', operator: 'eq', value: false } as filter)
      specialFilt.push({ field: 'refunds.status_id.id', operator: 'in', value: [ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED] } as filter)

      // console.log(specialFilt, filters)

      let { data: dataSource } = await getOrders(select, cPage, cPageSize, [...prepareInitialFilter(), ...specialFilt || filters], sorter)

      console.log(dataSource)

      dataSource = dataSource.filter((item: any) => item.refunds && item.refunds.some((refund: any) => refund.status_id !== null && refund.status_id.id <= ORDER_STATUS_ID_REQUESTED))

      console.log(dataSource)

      // Special cases
      const filterRefund = filters.find((x: { field: string }) => x.field === '')
      if (filterRefund) {
        dataSource = dataSource.filter((x: any) => {
          const totalRefundAmount = x.refunds.filter((r: any) => !r.deleted && r.type === 'supplier' && r.status_id !== null && r.status_id.id <= ORDER_STATUS_ID_REQUESTED).map((x: any) => x.amount || 0).reduce((prev: number, next: number) => prev + next, 0)
          if (filterRefund.operator === 'eq') {
            return (totalRefundAmount === Number(filterRefund.value))
          } else if (filterRefund.operator === 'gt') {
            return (totalRefundAmount > Number(filterRefund.value))
          } else if (filterRefund.operator === 'lt') {
            return (totalRefundAmount < Number(filterRefund.value))
          } else return true
        })
      }

      if (dataSource) {
        const orderId = dataSource.map((data: any) => data?.id)
        let { data: auditOrders } = await getLastAuditOrderWithProcessedStatus(orderId)

        // Clean auditOrders the repeated audit data and take the most old one.
        auditOrders = Object.values(
          auditOrders?.reduce((acc: any, item: any) => {
            const id = item?.resource_id

            if (!acc[id] || new Date(item?.created_at) < new Date(acc[id]?.created_at)) {
              acc[id] = item
            }
            return acc
          }, {})
        )

        // Clean auditOrders from audits with more than 15 days left to return.
        const today = new Date()
        if (issueTypeId === 7) {
          auditOrders = auditOrders.filter(item => {
            const createdDate = new Date(item.created_at)
            const expirationDate = new Date(createdDate)
            expirationDate.setDate(expirationDate.getDate() + 99)

            const remainingDays = (expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)

            return remainingDays <= 21
          })

          // filter the orders
          const allowedIds = auditOrders?.map((order: any) => order?.resource_id)
          dataSource = dataSource.filter((data: any) => allowedIds.includes(data?.id))
        }

        // add the remaining days to be able to order
        dataSource = dataSource.map((data: any) => {
          const audit = auditOrders?.find((au) => au.resource_id === data?.id)
          if (audit) {
            const createdDate = new Date(audit.created_at)
            const expirationDate = new Date(createdDate)
            expirationDate.setDate(expirationDate.getDate() + 99)
            const remainingDays = (expirationDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
            return { ...data, remainingDays: remainingDays, auditDate: audit?.created_at }
          } else {
            return { ...data, remainingDays: 0 }
          }
        })
        // Order dataSource for closer than 15 days
        dataSource = dataSource.sort((prev: any, next: any) => {
          return next.remainingDays < prev.remainingDays
        })
      }
      const tableProps = {
        dataSource: dataSource
      }
      tableProps.dataSource = (tableProps.dataSource as any)?.map((row: any) => {
        row.action_dates = { cancelation_date: row.cancelation_date, return_date: row.return_date }
        return row
      })

      tableProps.dataSource = tableProps.dataSource?.filter((x: any) => x.status_id && x.status_id > 0)
      setTableProps(tableProps)
      setTotalData(tableProps.dataSource.length)
      setLoading(false)
    }
    callingServer = false
  }

  const [totalData, setTotalData] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState(localStorage.getItem('ordersPage') ? parseInt(localStorage.getItem('ordersPage') || '1') : 1)
  const [pageSize, setPageSize] = useState(localStorage.getItem('ordersPageSize') ? parseInt(localStorage.getItem('ordersPageSize') || '10') : 10)
  const [loading, setLoading] = useState(false)
  const [additionalFilter, setAdditionalFilter] = useState<boolean>(false)

  // filter values
  const [filterId, setFilterId] = useState<number|undefined>(undefined)
  const [filteredIdState, setFilteredIdState] = useState<boolean>(false)
  const [filterAlias, setFilterAlias] = useState<string|undefined>(undefined)
  const [filteredAliasState, setFilteredAliasState] = useState<boolean>(false)
  const [filterLocation, setFilterLocation] = useState<string|undefined>(undefined)
  const [filteredLocationState, setFilteredLocationState] = useState<boolean>(false)
  const [filterMarketplaceId, setFilterMarketplaceId] = useState<Key[]|undefined>(undefined)
  const [filteredMarketplaceIdState, setFilteredMarketplaceIdState] = useState<boolean>(false)
  const [filterStatusId, setFilterStatusId] = useState<Key[]|undefined>(undefined)
  const [filteredStatusIdState, setFilteredStatusIdState] = useState<boolean>(false)

  const [filteredTotalState, setFilteredTotalState] = useState<boolean>(false)

  const t = useTranslate()

  useEffect(() => {
    // console.log('filters -->', filters, additionalFilter)
    if (!callingServer) {
      getOrdersList(additionalFilter)
    }
  }, [filters])

  useEffect(() => {
    // console.log('sorter -->', sorter)
    if (!callingServer) {
      if (tableProps.dataSource.length > 0) {
        getOrdersList()
      }
    }
  }, [sorter, currentPage, pageSize])

  let controlWidth = window.innerWidth
  const fixedMobile = controlWidth > 700 ? 'left' : false
  controlWidth = controlWidth < 728 ? 400 : 105

  const handleLocationChange = async (value: any, record: any, func: Function) => {
    if (record.id) {
      await supabaseClient
        .from('orders')
        .update({
          location: value,
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', record.id)
      const val = tableProps?.dataSource?.find(x => x.id === record.id)
      if (val) {
        val.location = value
      }
      func(true)
    }
  }

  async function exportOrders () {
    let selection = select
    let specialFilt = null

    selection = selection.replace(', supplements(supplement, volumetric, description, status(id, name))', ', refunds!inner(type, status_id, deleted, amount))')
    specialFilt = [...filters]
    specialFilt.push({ field: 'refunds.type', operator: 'eq', value: 'supplier' } as filter)
    specialFilt.push({ field: 'refunds.deleted', operator: 'eq', value: false } as filter)
    specialFilt.push({ field: 'refunds.status_id', operator: 'in', value: [ORDER_STATUS_ID_PENDING_PAYMENT, ORDER_STATUS_ID_REQUESTED] } as filter)

    let data = await getOrderExport(selection, specialFilt || filters, sorter)

    data = data?.filter((x: any) => x.status_id && x.status_id > 0)

    return dataToExport(data as any[])
  }

  return (
    <>
      <List
        resource='orders'
        headerProps={{
          extra: <>
            {issueTypeId !== 7 && <ExportToExcelButton filename='Pedidos' asyncFunction={() => exportOrders()} />}
          </>
        }}
      >
        <Table
          {...tableProps}
          rowKey="id"
          rowClassName={(record) => getConditionalClassName(record)}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalData,
            onChange (page, pageSize) {
              setCurrentPage(page)
              setPageSize(pageSize)
              localStorage.setItem('ordersPage', page.toString() || '')
              localStorage.setItem('ordersPageSize', pageSize.toString() || '')
            }
          }}
          onChange={(_pagination: any, _filters: any, sorter: any, _extra: any) => {
            if (sorter && sorter.order) {
              setSorter({ field: sorter.field, ascending: (sorter.order === 'ascend') })
            } else {
              setSorter({ field: 'id', ascending: false })
            }
          }}
          scroll={{ x: `${controlWidth}%`, y: '60vh' }}
          >
          <Table.Column
            key='id'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="id"
            align="center"
            title={t('order.fields.id')}
            filtered={filteredIdState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterId(Number(selectedKeys))
                  return selectedKeys
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'id')])
                  setFilterId(undefined)
                  setFilteredIdState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterId) {
                    if (filters.find((x: filter) => x.field === 'id')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'id')
                      updatedFilters[index].value = filterId

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'id', operator: 'eq', value: filterId }]])
                    }
                    setFilteredIdState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
            width= {fixedMobile ? 7 : 1}
          />
          <Table.Column
            key='alias'
            fixed = {fixedMobile ? 'left' : false}
            dataIndex="alias"
            align="center"
            title={t('order.fields.alias')}
            width= {fixedMobile ? 7 : 1}
            filtered={filteredAliasState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterAlias(selectedKeys.toString().toUpperCase())
                  return selectedKeys.toString().toUpperCase()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'alias')])
                  setFilterId(undefined)
                  setFilteredAliasState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterAlias) {
                    if (filters.find((x: filter) => x.field === 'alias')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'alias')
                      updatedFilters[index].value = filterAlias

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'alias', operator: 'eq', value: filterAlias }]])
                    }
                    setFilteredAliasState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='package_number'
            dataIndex="package_number"
            align="center"
            title={t('order.fields.package_number')}
            width= {fixedMobile ? 7 : 1}
          />
          <Table.Column
            key='location'
            dataIndex="location"
            align="center"
            title={t('order.fields.location')}
            width= {fixedMobile ? 12 : 2}
            render={(value, record: Order) => (
              <Space style={{ justifyContent: 'center' }}>
                <EditableRowField key={value} record={record} handleSave={handleLocationChange} />
              </Space>
            )}
            filtered={filteredLocationState}
            filterDropdown={(props) => (
              <FilterDropdown {...props}
                mapValue={(selectedKeys) => {
                  setFilterLocation(selectedKeys.toString() || undefined)
                  return selectedKeys.toString()
                }}
                clearFilters={() => {
                  setFilters([...filters.filter((x: filter) => x.field !== 'location')])
                  setFilterLocation(undefined)
                  setFilteredLocationState(false)
                  setAdditionalFilter(false)
                }}
                confirm={() => {
                  if (filterLocation) {
                    if (filters.find((x: filter) => x.field === 'location')) {
                      const updatedFilters = [...filters]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'location')
                      updatedFilters[index].value = filterLocation

                      setFilters([...updatedFilters])
                    } else {
                      setFilters([...filters, ...[{ field: 'location', operator: 'eq', value: filterLocation }]])
                    }
                    setFilteredLocationState(true)
                    setAdditionalFilter(true)
                  }
                }}
              >
                <Input type='text' />
              </FilterDropdown>
            )}
          />
          <Table.Column
            key='created_at'
            dataIndex="created_at"
            align="center"
            title={t('order.fields.createdAt')}
            render={(value) => <DateField format={DEFAULT_DATE_TIME_FORMAT} value={value}/>}
            width= {fixedMobile ? 10 : 1}
            filterDropdown={() => (
              <DateFilterDropdown
                field='created_at'
                filters={filters}
                setFilters={(newFilter: any, method: string) => {
                  if (newFilter.length > 0 && method === 'merge') {
                    let filtersToAdd = [...filters]

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'gte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'gte')
                      updatedFilters[index].value = newFilter[0].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[0]]]
                    }

                    if (filters.find((x: filter) => x.field === 'created_at' && x.operator === 'lte')) {
                      const updatedFilters = [...filtersToAdd]
                      const index = updatedFilters.findIndex((x: filter) => x.field === 'created_at' && x.operator === 'lte')
                      updatedFilters[index].value = newFilter[1].value

                      filtersToAdd = [...updatedFilters]
                    } else {
                      filtersToAdd = [...filtersToAdd, ...[newFilter[1]]]
                    }

                    setFilters([...filtersToAdd])
                    setAdditionalFilter(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== 'created_at')])
                    setAdditionalFilter(false)
                  }
                }}
              />
            )}
          />
          <Table.Column
            key='auditDate'
            dataIndex="auditDate"
            align="center"
            title={t('order.fields.auditDate')}
            width= {fixedMobile ? 7 : 1}
            render={(value) => {
              if (value) {
                const date = new Date(value)
                const newDate = date.setDate(date.getDate() + 99)
                return <DateField format={DEFAULT_DATE_TIME_FORMAT} value={newDate}/>
              } else {
                return null
              }
            }}
          />
          <Table.Column
            dataIndex={['marketplace_id', 'name']}
            align="center"
            title={t('order.fields.marketplace')}
            width= {fixedMobile ? 7 : 1}
            key="marketplace_id"
            filtered={filteredMarketplaceIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterMarketplaceId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'marketplace_id')])
                setFilterMarketplaceId(undefined)
                setFilteredMarketplaceIdState(false)
                setAdditionalFilter(false)
              }
              props.confirm = () => {
                if (filterMarketplaceId && filterMarketplaceId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'marketplace_id')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'marketplace_id')
                    updatedFilters[index].value = filterMarketplaceId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'marketplace_id', operator: 'in', value: filterMarketplaceId }]])
                  }
                  setFilteredMarketplaceIdState(true)
                  setAdditionalFilter(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='marketplaces'
                optionLabel='name'
                optionValue='id'
                filters={[{
                  field: 'deleted',
                  operator: 'eq',
                  value: false
                }]}
              />
            }}
          />
          <Table.Column
            key='payment_method_id'
            dataIndex={['payment_method_id', 'name']}
            align="center"
            title={t('order.fields.paymentMethod')}
            width= {fixedMobile ? 7 : 1}
          />
          <Table.Column
            key='total'
            dataIndex="total"
            align="center"
            title={t('order.fields.billingAmount')}
            width= {fixedMobile ? 10 : 1}
            render={(value: number, record: any) => {
              const totalAmount = record.refunds
                .filter((x: any) => !x.deleted && x.type === 'supplier' && x.status_id !== null && x.status_id.id <= ORDER_STATUS_ID_REQUESTED)
                .map((x: any) => x.amount || 0)
                .reduce((prev: number, next: number) => prev + next, 0)
              return currency(totalAmount)
            }}
            filtered={filteredTotalState}
            filterDropdown={() => (
              <QuantityFilterDropdown
                field={''}
                filters={filters}
                setFilters={(newFilter: any) => {
                  if (Array.isArray(newFilter)) {
                    setFilters([...newFilter])
                    setFilteredTotalState(true)
                    setAdditionalFilter(true)
                  } else {
                    setFilters([...filters.filter((x: filter) => x.field !== '')])
                    setFilteredTotalState(false)
                    setAdditionalFilter(false)
                  }
                }}
              />
            )}
          />
          <Table.Column
            key='refundStatus'
            dataIndex={'refundStatus'}
            align="center"
            title={t('order.fields.refundStatus')}
            width= {fixedMobile ? 7 : 1}
            render={(value, record: any) => {
              if (record?.refunds?.length) {
                const refundWithLowestStatus = record?.refunds?.reduce((lowest: any, refund: any) => {
                  return refund?.status_id?.id < lowest?.status_id?.id ? refund : lowest
                }, record?.refunds[0])
                return <p>{refundWithLowestStatus?.status_id?.name}</p>
              } else {
                return null
              }
            }}
          />
          <Table.Column
            key="status_id"
            dataIndex={['status', 'name']}
            align="center"
            title={t('order.fields.status')}
            width= {fixedMobile ? 10 : 1}
            render={(value, record: Order) => (
              <Tag color={getColorLabel(value, record?.prev_status_id)}>
                {capitalizeFirstLetter(value)}
              </Tag>
            )}
            filtered={filteredStatusIdState}
            filterDropdown={(props: FilterDropdownProps) => {
              props.setSelectedKeys = setFilterStatusId
              props.clearFilters = () => {
                setFilters([...filters.filter((x: filter) => x.field !== 'status_id' && x.operator === 'in')])
                setFilterStatusId(undefined)
                setFilteredStatusIdState(false)
                setAdditionalFilter(false)
              }
              props.confirm = () => {
                if (filterStatusId && filterStatusId.length > 0) {
                  if (filters.find((x: filter) => x.field === 'status_id' && x.operator === 'in')) {
                    const updatedFilters = [...filters]
                    const index = updatedFilters.findIndex((x: filter) => x.field === 'status_id' && x.operator === 'in')
                    updatedFilters[index].value = filterStatusId

                    setFilters([...updatedFilters])
                  } else {
                    setFilters([...filters, ...[{ field: 'status_id', operator: 'in', value: filterStatusId }]])
                  }
                  setFilteredStatusIdState(true)
                  setAdditionalFilter(true)
                }
              }
              return <SelectFilterDropdown
                props={props}
                entity='status'
                optionLabel='name'
                optionValue='id'
                sorters={[{
                  field: 'id',
                  order: 'asc'
                }]}
              />
            }}
          />
          <Table.Column<Order>
            fixed="right"
            title={t('table.actions')}
            dataIndex="actions"
            key="actions"
            align="center"
            width= {fixedMobile ? 7 : 1}
            // width= {20}
            render={(_, record) => {
              if (record.locked) {
                return (
                  <>
                    <Icons.LockOutlined style={{ color: '#f5222d', fontSize: 20 }} />
                    {record.locked_by === currentUserEmail() && (
                      <ShowButton
                        resourceNameOrRouteName='orders'
                        hideText
                        style={{ color: 'green', fontSize: '15px', marginLeft: 5 }}
                        recordItemId={record.id}
                      />
                    )}
                    <p>{record?.locked_by}</p>
                  </>
                )
              }
              return (
                <ShowButton
                  resourceNameOrRouteName='orders'
                  hideText
                  style={{ color: 'green', fontSize: '15px' }}
                  recordItemId={record.id}
                />
              )
            }}
          />
        </Table>
      </List>
    </>
  )
}
