import { ClientStatusesMapper, IssueTypesConfig, IssueSubTypesMapper, IssueTypeIdTypes, IssueTypesMapper, IssueStatusMapper, BusinessStatusMapper, EstimatedCostsStatusMapper, SubIssueTypeIdTypes, SubIssueTypesConfig, DecreaseTypesMapper, OrderStatusMapper, ShipmentMethodMapper, PaymentPurchaseMethodMapper, RefundStatusMapper, IssueLocationMapper } from 'types/types'
import {
  CLIENT_STATUS_CONTACTED_ID,
  CLIENT_STATUS_CONTACTED_TEXT,
  CLIENT_STATUS_NO_REFUND_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT,
  CLIENT_STATUS_NO_REFUND_TEXT,
  CLIENT_STATUS_PENDING_REFUND_COSTS_ID,
  CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  CLIENT_STATUS_REFUNDED_ID,
  CLIENT_STATUS_REFUNDED_TEXT,
  CLIENT_STATUS_TIPSA_CONTACTED_ID,
  CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  ISSUE_SUB_TYPE_BROKEN_ORDER_ID,
  ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_ID,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  ISSUE_SUB_TYPE_CROSSED_ORDER_ID,
  ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  ISSUE_SUB_TYPE_GUARANTEE_ID,
  ISSUE_SUB_TYPE_GUARANTEE_TEXT,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_ID,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  ISSUE_SUB_TYPE_PENALTY_ID,
  ISSUE_SUB_TYPE_PENALTY_TEXT,
  ISSUE_SUB_TYPE_REFUND_ID,
  ISSUE_SUB_TYPE_REFUND_TEXT,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT,
  ISSUE_TYPE_AFTER_SALE_ID,
  ISSUE_TYPE_AFTER_SALE_TEXT,
  ISSUE_TYPE_REFUND_CANARY_ID,
  ISSUE_TYPE_REFUND_CANARY_TEXT,
  ISSUE_TYPE_REFUND_MADRID_ID,
  ISSUE_TYPE_REFUND_MADRID_TEXT,
  ISSUE_TYPE_TRANSPORT_ID,
  ISSUE_TYPE_TRANSPORT_TEXT,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_ID,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  ISSUE_STATUS_OFFICE_RECEIVED_ID,
  ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_RESOLUTION_ID,
  ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  ISSUE_STATUS_REFUNDED_ID,
  ISSUE_STATUS_REFUNDED_TEXT,
  ISSUE_STATUS_REFUND_READY_ID,
  ISSUE_STATUS_REFUND_READY_TEXT,
  ISSUE_STATUS_REFUND_RECEIVED_ID,
  ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_SENT_TO_MADRID_ID,
  ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_ID,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  BUSINESS_STATUS_REQUESTED_ID,
  BUSINESS_STATUS_LABELLED_ID,
  BUSINESS_STATUS_RECEIVED_ID,
  BUSINESS_STATUS_SENT_ID,
  BUSINESS_STATUS_DELIVERED_ID,
  BUSINESS_STATUS_CANCELLED_ID,
  BUSINESS_STATUS_REQUESTED_TEXT,
  BUSINESS_STATUS_LABELLED_TEXT,
  BUSINESS_STATUS_RECEIVED_TEXT,
  BUSINESS_STATUS_SENT_TEXT,
  BUSINESS_STATUS_DELIVERED_TEXT,
  BUSINESS_STATUS_CANCELLED_TEXT,
  ACEPTED_STATUS_ID,
  ACEPTED_STATUS_TEXT,
  ADVERTISEMENT_STATUS_ID,
  ADVERTISEMENT_STATUS_TEXT,
  CANCELLED_STATUS_ID,
  CANCELLED_STATUS_TEXT,
  SENT_BUDGE_STATUS_ID,
  SENT_BUDGE_STATUS_TEXT,
  ISSUE_STATUS_CONTACTED_TIPSA_ID,
  ISSUE_STATUS_CONTACTED_TIPSA_TEXT,
  ISSUE_SUB_TYPE_WRONG_ORDERS_ID,
  ISSUE_SUB_TYPE_WRONG_ORDERS_TEXT,
  CLIENT_STATUS_PENDING_RESOLUTIONS_ID,
  CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  CLIENT_STATUS_NEW_PURCHASE_ID,
  CLIENT_STATUS_NEW_PURCHASE_TEXT,
  CLIENT_STATUS_SHIPPING_NEW_ORDER_ID,
  CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT,
  ISSUE_TYPE_WEREHOUSE_ID,
  ISSUE_TYPE_WEREHOUSE_TEXT,
  ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID,
  ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_TEXT,
  ISSUE_STATUS_REQUESTED_POD_SUPPLIER_ID,
  ISSUE_STATUS_REQUESTED_POD_SUPPLIER_TEXT,
  ISSUE_STATUS_ORDER_LOCATED_ID,
  ISSUE_STATUS_ORDER_LOCATED_TEXT,
  ISSUE_STATUS_NO_REFUND_TEXT,
  ISSUE_STATUS_NO_REFUND_ID,
  CLIENT_STATUS_WARRANTY_ACCEPTED_ID,
  CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT,
  CLIENT_STATUS_WARRANTY_REJECTED_ID,
  CLIENT_STATUS_WARRANTY_REJECTED_TEXT,
  CLIENT_STATUS_SUPPLEMENT_SENT_ID,
  CLIENT_STATUS_SUPPLEMENT_SENT_TEXT,
  ISSUE_STATUS_LOCALIZED_LUMP_ID,
  ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID,
  ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT,
  ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID,
  ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT,
  CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID,
  CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT,
  CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID,
  CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT,
  CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID,
  ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID,
  ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT,
  ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID,
  ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT,
  ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID,
  ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_TEXT,
  CLIENT_STATUS_PENDING_LOCATION_TEXT,
  CLIENT_STATUS_PENDING_LOCATION_ID,
  ISSUE_STATUS_CLAIMING_SUPPLIER_ID,
  ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT,
  ISSUE_STATUS_CLAIMING_TIPSA_TEXT,
  ISSUE_STATUS_CLAIMING_TIPSA_ID,
  ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID,
  ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT,
  ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID,
  ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT,
  CLIENT_STATUS_CLIENT_NOT_FOUND_ID,
  CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT,
  CLIENT_STATUS_CLIENT_LOCATED_ID,
  CLIENT_STATUS_CLIENT_LOCATED_TEXT,
  ISSUE_STATUS_NO_LOCALIZED_LUMP_ID,
  ISSUE_STATUS_NO_LOCALIZED_LUMP_TEXT,
  ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID,
  ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT,
  ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID,
  ISSUE_STATUS_ORDER_NOT_FOUND_TEXT,
  ISSUE_STATUS_ORDER_NOT_FOUND_ID,
  CLIENT_STATUS_CLIENT_CONTACTED_ID,
  CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  IVA_NOT_RECEIVED_ID,
  IVA_NOT_RECEIVED_TEXT,
  LOST_ORDERS_WITH_COMPENSATION_ID,
  LOST_ORDERS_WITH_COMPENSATION_TEXT,
  LOST_ORDERS_WITHOUT_COMPENSATION_ID,
  LOST_ORDERS_WITHOUT_COMPENSATION_TEXT,
  RETURN_COSTS_ID,
  RETURN_COSTS_TEXT,
  RETURN_REFUSED_ID,
  RETURN_REFUSED_TEXT,
  RETURN_WITH_COUPON_VOUCHER_ID,
  RETURN_WITH_COUPON_VOUCHER_TEXT,
  ORDER_STATUS_ID_PENDING_PAYMENT,
  ORDER_STATUS_ID_REQUESTED,
  PROCESSED_STATUS,
  CANCELLED_STATUS,
  SUPPLEMENT_STATUS,
  ADMITTED_STATUS,
  NO_STOCK_STATUS,
  PREPARED_STATUS,
  SHIPPED_STATUS,
  DELIVERED_STATUS,
  PENALIZATION_STATUS,
  REFUND_STATUS,
  PARCIAL_RETURN_STATUS,
  DECREASE_RETURN_STATUS,
  INTERNAL_SPENDING_RETURN_STATUS,
  ORDER_STATUS_ID_ON_HOLD,
  ORDER_STATUS_ID_DENIED,
  PENDING_PAYMENT_STATUS_TEXT,
  REQUEST_STATUS_TEXT,
  PROCESS_STATUS_TEXT,
  CANCEL_STATUS_TEXT,
  SUPPLEMENT_STATUS_TEXT,
  ADMITTED_STATUS_TEXT,
  NO_STOCK_STATUS_TEXT,
  PREPARED_STATUS_TEXT,
  SHIPPED_STATUS_TEXT,
  DELIVERED_STATUS_TEXT,
  PENALIZATION_STATUS_TEXT,
  REFUND_STATUS_TEXT,
  PARCIAL_RETURN_STATUS_TEXT,
  DECREASE_RETURN_STATUS_TEXT,
  INTERNAL_SPENDING_RETURN_STATUS_TEXT,
  ORDER_STATUS_ON_HOLD_TEXT,
  ORDER_STATUS_DENIED_TEXT,
  RETURN_STATUS,
  RETURN_STATUS_TEXT,
  EXPRESS_SHIPMENT_METHOD_ID,
  EXPRESS_SHIPMENT_METHOD_TEXT,
  ESTANDAR_SHIPMENT_METHOD_ID,
  ESTANDAR_SHIPMENT_METHOD_TEXT,
  ECONOMY_SHIPMENT_METHOD_ID,
  ECONOMY_SHIPMENT_METHOD_TEXT,
  PAYMENT_TYPE_BIZUM,
  PAYMENT_TYPE_BIZUM_ID,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_CARD_ID,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_PAYPAL_ID,
  PAYMENT_TYPE_TRANFER,
  PAYMENT_TYPE_TRANFER_ID,
  ISSUE_SUB_TYPE_NO_STOCK_TEXT,
  ISSUE_SUB_TYPE_NO_STOCK_ID,
  ISSUE_SUB_TYPE_ERRONEOUS_DEFECTIVE_ORDERS_ID,
  ISSUE_SUB_TYPE_ERRONEOUS_DEFECTIVE_ORDERS_TEXT,
  SEND_TO_MADRID_TEXT,
  SEND_TO_MADRID_ID,
  CLIENT_ID,
  CLIENT_TEXT,
  OF_CANARY_ID,
  OF_CANARY_TEXT,
  MADRID_ID,
  MADRID_TEXT,
  SENT_SUPPLIER_ID,
  SENT_SUPPLIER_TEXT,
  REFUND_ORDER_PROVIDER_TYPE_ID,
  REFUND_ORDER_PROVIDER_TEXT,
  REFUND_ORDER_PROVIDER_LESS_THAN_90_DAYS_TYPE_ID,
  REFUND_ORDER_PROVIDER_LESS_THAN_90_DAYS_TYPE_TEXT,
  ADMITTED_REFUND_STATUS_ID,
  PENDING_REFUND_PAYMENT_STATUS_ID,
  PENDING_REFUND_PAYMENT_STATUS_TEXT,
  ADMITTED_REFUND_STATUS_TEXT,
  REQUESTED_REFUND_STATUS_ID,
  REQUESTED_REFUND_STATUS_TEXT,
  PAYMENT_TYPE_CARDABANCAOCTA_ID,
  PAYMENT_TYPE_CARDABANCAFER_ID,
  PAYMENT_TYPE_CARDABANCAFER,
  PAYMENT_TYPE_CARDSANTPA,
  PAYMENT_TYPE_CARDSANTPA_ID,
  PAYMENT_TYPE_CARDBBVAFER,
  PAYMENT_TYPE_CARDBBVAFER_ID,
  PAYMENT_TYPE_CARDABANCAOCTA,
  ALL_CLOSE_ISSUE_ID,
  ALL_CLOSE_ISSUE_TEXT
} from './constants'

export const ISSUE_TYPES_MAPPER: IssueTypesMapper = {
  [ISSUE_TYPE_REFUND_MADRID_ID]: ISSUE_TYPE_REFUND_MADRID_TEXT,
  [ISSUE_TYPE_REFUND_CANARY_ID]: ISSUE_TYPE_REFUND_CANARY_TEXT,
  [ISSUE_TYPE_AFTER_SALE_ID]: ISSUE_TYPE_AFTER_SALE_TEXT,
  [ISSUE_TYPE_TRANSPORT_ID]: ISSUE_TYPE_TRANSPORT_TEXT,
  [ISSUE_TYPE_WEREHOUSE_ID]: ISSUE_TYPE_WEREHOUSE_TEXT,
  [REFUND_ORDER_PROVIDER_TYPE_ID]: REFUND_ORDER_PROVIDER_TEXT,
  [REFUND_ORDER_PROVIDER_LESS_THAN_90_DAYS_TYPE_ID]: REFUND_ORDER_PROVIDER_LESS_THAN_90_DAYS_TYPE_TEXT,
  [ALL_CLOSE_ISSUE_ID]: ALL_CLOSE_ISSUE_TEXT
}

export const ISSUE_SUB_TYPES_REF_MADRID_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_PENALTY_ID]: ISSUE_SUB_TYPE_PENALTY_TEXT,
  [ISSUE_SUB_TYPE_CANCEL_STORAGE_ID]: ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  [ISSUE_SUB_TYPE_NO_STOCK_ID]: ISSUE_SUB_TYPE_NO_STOCK_TEXT,
  [ISSUE_SUB_TYPE_ERRONEOUS_DEFECTIVE_ORDERS_ID]: ISSUE_SUB_TYPE_ERRONEOUS_DEFECTIVE_ORDERS_TEXT
}

export const ISSUE_SUB_TYPES_REF_CANARY_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_PARTIAL_REFUND_ID]: ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  [ISSUE_SUB_TYPE_REFUND_ID]: ISSUE_SUB_TYPE_REFUND_TEXT,
  [ISSUE_SUB_TYPE_WRONG_ORDERS_ID]: ISSUE_SUB_TYPE_WRONG_ORDERS_TEXT
}

export const ISSUE_SUB_TYPES_AFTER_SALE_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_GUARANTEE_ID]: ISSUE_SUB_TYPE_GUARANTEE_TEXT
}

export const ISSUE_SUB_TYPES_TRANSPORT_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID]: ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  [ISSUE_SUB_TYPE_BROKEN_ORDER_ID]: ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  [ISSUE_SUB_TYPE_CROSSED_ORDER_ID]: ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  [ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID]: ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  [ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID]: ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT,
  [ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID]: ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_TEXT
}

export const ISSUE_SUB_TYPES_WEREHOUSE_MAPPER: IssueSubTypesMapper = {
  [ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID]: ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_TEXT
}

export const REFUND_ORDER_PROVIDER_MAPPER: any = {
  [ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID]: undefined
}

export const ISSUE_SUB_TYPES_MAPPER: IssueSubTypesMapper = {
  ...ISSUE_SUB_TYPES_REF_MADRID_MAPPER,
  ...ISSUE_SUB_TYPES_REF_CANARY_MAPPER,
  ...ISSUE_SUB_TYPES_AFTER_SALE_MAPPER,
  ...ISSUE_SUB_TYPES_TRANSPORT_MAPPER,
  ...ISSUE_SUB_TYPES_WEREHOUSE_MAPPER
}

export const CLIENT_STATUSES_REF_CANARY_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_TIPSA_CONTACTED_ID]: CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT,
  [CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID]: CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT,
  [CLIENT_STATUS_PENDING_RESOLUTIONS_ID]: CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_SHIPPING_NEW_ORDER_ID]: CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT
}

export const CLIENT_STATUSES_REF_AFTER_SALE_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_TIPSA_CONTACTED_ID]: CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT,
  [CLIENT_STATUS_NO_REFUND_ID]: CLIENT_STATUS_NO_REFUND_TEXT,
  [CLIENT_STATUS_PENDING_RESOLUTIONS_ID]: CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  [CLIENT_STATUS_WARRANTY_ACCEPTED_ID]: CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT,
  [CLIENT_STATUS_WARRANTY_REJECTED_ID]: CLIENT_STATUS_WARRANTY_REJECTED_TEXT
}

export const PENALTY_CLIENT_STATUSES_REF_MADRID_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_PENDING_REFUND_COSTS_ID]: CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const CANCEL_STORAGE_CLIENT_STATUSES_REF_MADRID_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const PARTIAL_REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const WRONG_ORDERS_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_PENDING_RESOLUTIONS_ID]: CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_SHIPPING_NEW_ORDER_ID]: CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT
}

export const ORDERS_NOT_LOCATED_CLIENT_STATUSES_REF_STORAGE_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const GUARANTEE_CLIENT_STATUSES_REF_AFTER_SALE_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_PENDING_RESOLUTIONS_ID]: CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  [CLIENT_STATUS_WARRANTY_ACCEPTED_ID]: CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT,
  [CLIENT_STATUS_WARRANTY_REJECTED_ID]: CLIENT_STATUS_WARRANTY_REJECTED_TEXT
}

export const DOUBLE_SHIPMENT_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_SUPPLEMENT_SENT_ID]: CLIENT_STATUS_SUPPLEMENT_SENT_TEXT
}

export const CROSSED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  [CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID]: CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  [CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID]: CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT,
  [CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID]: CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const BROKEN_ORDER_CROSSED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  [CLIENT_STATUS_PENDING_LOCATION_ID]: CLIENT_STATUS_PENDING_LOCATION_TEXT,
  [ISSUE_STATUS_LOCALIZED_LUMP_ID]: ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  [ISSUE_STATUS_NO_LOCALIZED_LUMP_ID]: ISSUE_STATUS_NO_LOCALIZED_LUMP_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const POORLY_PREPARED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT
}

export const UNTRACEABLE_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  [ISSUE_STATUS_LOCALIZED_LUMP_ID]: ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_PENDING_LOCATION_ID]: CLIENT_STATUS_PENDING_LOCATION_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const UNDELIVERED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_LOCATED_ID]: CLIENT_STATUS_CLIENT_LOCATED_TEXT,
  [CLIENT_STATUS_CLIENT_NOT_FOUND_ID]: CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT
}

export const NOT_LOCATED_CLIENT_STATUSES_WEREHOUSE_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CLIENT_CONTACTED_ID]: CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT
}

export const CLIENT_STATUSES_REF_TRANSPORT_MAPPER: ClientStatusesMapper = {
  [CLIENT_STATUS_CONTACTED_ID]: CLIENT_STATUS_CONTACTED_TEXT,
  [CLIENT_STATUS_TIPSA_CONTACTED_ID]: CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  [CLIENT_STATUS_REFUNDED_ID]: CLIENT_STATUS_REFUNDED_TEXT,
  [CLIENT_STATUS_NO_REFUND_ID]: CLIENT_STATUS_NO_REFUND_TEXT,
  [CLIENT_STATUS_PENDING_RESOLUTIONS_ID]: CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  [CLIENT_STATUS_WARRANTY_ACCEPTED_ID]: CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT,
  [CLIENT_STATUS_WARRANTY_REJECTED_ID]: CLIENT_STATUS_WARRANTY_REJECTED_TEXT,
  [CLIENT_STATUS_SUPPLEMENT_SENT_ID]: CLIENT_STATUS_SUPPLEMENT_SENT_TEXT,
  [CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID]: CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  [CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID]: CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT,
  [CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID]: CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT,
  [CLIENT_STATUS_NEW_PURCHASE_ID]: CLIENT_STATUS_NEW_PURCHASE_TEXT,
  [CLIENT_STATUS_PENDING_LOCATION_ID]: CLIENT_STATUS_PENDING_LOCATION_TEXT,
  [CLIENT_STATUS_CLIENT_LOCATED_ID]: CLIENT_STATUS_CLIENT_LOCATED_TEXT,
  [CLIENT_STATUS_CLIENT_NOT_FOUND_ID]: CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT
}

export const CLIENT_STATUSES_MAPPER: ClientStatusesMapper = {
  ...UNDELIVERED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...UNTRACEABLE_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...POORLY_PREPARED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...BROKEN_ORDER_CROSSED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...CROSSED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...DOUBLE_SHIPMENT_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
  ...GUARANTEE_CLIENT_STATUSES_REF_AFTER_SALE_MAPPER,
  ...ORDERS_NOT_LOCATED_CLIENT_STATUSES_REF_STORAGE_MAPPER,
  ...WRONG_ORDERS_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER,
  ...REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER,
  ...REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER,
  ...PARTIAL_REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER,
  ...CANCEL_STORAGE_CLIENT_STATUSES_REF_MADRID_MAPPER,
  ...PENALTY_CLIENT_STATUSES_REF_MADRID_MAPPER,
  ...NOT_LOCATED_CLIENT_STATUSES_WEREHOUSE_MAPPER
}

export const ISSUE_STATUS_REF_MADRID_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID]: ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  [ISSUE_STATUS_REFUND_READY_ID]: ISSUE_STATUS_REFUND_READY_TEXT,
  [ISSUE_STATUS_PENDING_BRAND_CONTACT_ID]: ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT
}

export const ISSUE_STATUS_REF_CANARY_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID]: ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT,
  [ISSUE_STATUS_OFFICE_RECEIVED_ID]: ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  [ISSUE_STATUS_SENT_TO_MADRID_ID]: ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  [ISSUE_STATUS_PENDING_BRAND_CONTACT_ID]: ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  [ISSUE_STATUS_CONTACTED_TIPSA_ID]: ISSUE_STATUS_CONTACTED_TIPSA_TEXT
}

export const ISSUE_STATUS_REF_AFTER_SALE_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CONTACTED_MARKETPLACE_ID]: ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  [ISSUE_STATUS_REFUNDED_ID]: ISSUE_STATUS_REFUNDED_TEXT,
  [ISSUE_STATUS_OFFICE_RECEIVED_ID]: ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  [ISSUE_STATUS_SENT_TO_MADRID_ID]: ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  [ISSUE_STATUS_PENDING_RESOLUTION_ID]: ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  [ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID]: ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  [ISSUE_STATUS_PENDING_BRAND_CONTACT_ID]: ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  [ISSUE_STATUS_CONTACTED_TIPSA_ID]: ISSUE_STATUS_CONTACTED_TIPSA_TEXT
}

export const ISSUE_STATUS_REF_WEREHOUSE_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_REFUND_RECEIVED_ID]: ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_REQUESTED_POD_SUPPLIER_ID]: ISSUE_STATUS_REQUESTED_POD_SUPPLIER_TEXT,
  [ISSUE_STATUS_ORDER_LOCATED_ID]: ISSUE_STATUS_ORDER_LOCATED_TEXT,
  [ISSUE_STATUS_NO_REFUND_ID]: ISSUE_STATUS_NO_REFUND_TEXT,
  [ISSUE_STATUS_PENDING_RESOLUTION_ID]: ISSUE_STATUS_PENDING_RESOLUTION_TEXT
}

export const ISSUE_STATUS_REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_LOCALIZED_LUMP_ID]: ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  [ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID]: ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT,
  [ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID]: ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT,
  [ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID]: ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT,
  [ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID]: ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT,
  [ISSUE_STATUS_CLAIMING_SUPPLIER_ID]: ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT,
  [ISSUE_STATUS_CLAIMING_TIPSA_ID]: ISSUE_STATUS_CLAIMING_TIPSA_TEXT,
  [ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID]: ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT,
  [ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID]: ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID]: ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  [ISSUE_STATUS_ORDER_LOCATED_ID]: ISSUE_STATUS_ORDER_LOCATED_TEXT,
  [ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID]: ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT,
  [ISSUE_STATUS_ORDER_NOT_FOUND_ID]: ISSUE_STATUS_ORDER_NOT_FOUND_TEXT,
  [ISSUE_STATUS_PENDING_RESOLUTION_ID]: ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  [ISSUE_STATUS_NO_REFUND_ID]: ISSUE_STATUS_NO_REFUND_TEXT
}

export const ORDER_STATUS_MAPPER: OrderStatusMapper = {
  [ORDER_STATUS_ID_PENDING_PAYMENT]: PENDING_PAYMENT_STATUS_TEXT,
  [ORDER_STATUS_ID_REQUESTED]: REQUEST_STATUS_TEXT,
  [PROCESSED_STATUS]: PROCESS_STATUS_TEXT,
  [CANCELLED_STATUS]: CANCEL_STATUS_TEXT,
  [RETURN_STATUS]: RETURN_STATUS_TEXT,
  [SUPPLEMENT_STATUS]: SUPPLEMENT_STATUS_TEXT,
  [ADMITTED_STATUS]: ADMITTED_STATUS_TEXT,
  [NO_STOCK_STATUS]: NO_STOCK_STATUS_TEXT,
  [PREPARED_STATUS]: PREPARED_STATUS_TEXT,
  [SHIPPED_STATUS]: SHIPPED_STATUS_TEXT,
  [DELIVERED_STATUS]: DELIVERED_STATUS_TEXT,
  [PENALIZATION_STATUS]: PENALIZATION_STATUS_TEXT,
  [REFUND_STATUS]: REFUND_STATUS_TEXT,
  [PARCIAL_RETURN_STATUS]: PARCIAL_RETURN_STATUS_TEXT,
  [DECREASE_RETURN_STATUS]: DECREASE_RETURN_STATUS_TEXT,
  [INTERNAL_SPENDING_RETURN_STATUS]: INTERNAL_SPENDING_RETURN_STATUS_TEXT,
  [ORDER_STATUS_ID_ON_HOLD]: ORDER_STATUS_ON_HOLD_TEXT,
  [ORDER_STATUS_ID_DENIED]: ORDER_STATUS_DENIED_TEXT
}

export const ISSUE_STATUS_DOUBLE_SHIPMENT_REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID]: ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT,
  [ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID]: ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT
}

export const ISSUE_STATUS_BROKEN_ORDER_REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_LOCALIZED_LUMP_ID]: ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  [ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID]: ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT
}

export const ISSUE_STATUS_CROSSED_ORDER_REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID]: ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT,
  [ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID]: ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT
}

export const ISSUE_STATUS_POORLY_PREPARED_ORDERS_REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID]: ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  [ISSUE_STATUS_ORDER_LOCATED_ID]: ISSUE_STATUS_ORDER_LOCATED_TEXT,
  [ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID]: ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT,
  [ISSUE_STATUS_ORDER_NOT_FOUND_ID]: ISSUE_STATUS_ORDER_NOT_FOUND_TEXT
}

export const ISSUE_STATUS_UNTRACEABLE_ORDER__REF_TRANSPORT_MAPPER: IssueStatusMapper = {
  [ISSUE_STATUS_CLAIMING_SUPPLIER_ID]: ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT,
  [ISSUE_STATUS_CLAIMING_TIPSA_ID]: ISSUE_STATUS_CLAIMING_TIPSA_TEXT,
  [ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID]: ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT,
  [ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID]: ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT,
  [ISSUE_STATUS_PENDING_RESOLUTION_ID]: ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  [ISSUE_STATUS_NO_REFUND_ID]: ISSUE_STATUS_NO_REFUND_TEXT
}

export const ISSUE_STATUS_MAPPER: IssueStatusMapper = {
  ...ISSUE_STATUS_REF_MADRID_MAPPER,
  ...ISSUE_STATUS_REF_CANARY_MAPPER,
  ...ISSUE_STATUS_REF_AFTER_SALE_MAPPER,
  ...ISSUE_STATUS_REF_TRANSPORT_MAPPER,
  ...ISSUE_STATUS_REF_WEREHOUSE_MAPPER
}

export const ISSUE_CONFIG: IssueTypesConfig = {
  [ISSUE_TYPE_REFUND_MADRID_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_REF_MADRID_MAPPER
  },
  [ISSUE_TYPE_REFUND_CANARY_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_REF_CANARY_MAPPER
  },
  [ISSUE_TYPE_WEREHOUSE_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_WEREHOUSE_MAPPER
  },
  [ISSUE_TYPE_AFTER_SALE_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_AFTER_SALE_MAPPER
  },
  [ISSUE_TYPE_TRANSPORT_ID]: {
    issueSubTypes: ISSUE_SUB_TYPES_TRANSPORT_MAPPER
  },
  [REFUND_ORDER_PROVIDER_TYPE_ID]: {
    issueSubTypes: REFUND_ORDER_PROVIDER_MAPPER
  },
  [REFUND_ORDER_PROVIDER_LESS_THAN_90_DAYS_TYPE_ID]: {
    issueSubTypes: REFUND_ORDER_PROVIDER_MAPPER
  },
  [ALL_CLOSE_ISSUE_ID]: {
    issueSubTypes: REFUND_ORDER_PROVIDER_MAPPER
  }
}

export const CLIENT_ISSUE_STATUS_ISSUE_CONFIG: SubIssueTypesConfig = {
  [ISSUE_SUB_TYPE_PENALTY_ID]: {
    clientStatuses: PENALTY_CLIENT_STATUSES_REF_MADRID_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_MADRID_MAPPER
  },
  [ISSUE_SUB_TYPE_CANCEL_STORAGE_ID]: {
    clientStatuses: CANCEL_STORAGE_CLIENT_STATUSES_REF_MADRID_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_MADRID_MAPPER
  },
  [ISSUE_SUB_TYPE_REFUND_ID]: {
    clientStatuses: REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_CANARY_MAPPER
  },
  [ISSUE_SUB_TYPE_PARTIAL_REFUND_ID]: {
    clientStatuses: PARTIAL_REFUND_CLIENT_STATUSES_REF_CANARY_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_CANARY_MAPPER
  },
  [ISSUE_SUB_TYPE_WRONG_ORDERS_ID]: {
    clientStatuses: WRONG_ORDERS_CLIENT_STATUSES_REF_CANARY_MAPPER_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_CANARY_MAPPER
  },
  [ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID]: {
    clientStatuses: ORDERS_NOT_LOCATED_CLIENT_STATUSES_REF_STORAGE_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_WEREHOUSE_MAPPER
  },
  [ISSUE_SUB_TYPE_GUARANTEE_ID]: {
    clientStatuses: GUARANTEE_CLIENT_STATUSES_REF_AFTER_SALE_MAPPER,
    issueStatuses: ISSUE_STATUS_REF_AFTER_SALE_MAPPER
  },
  [ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID]: {
    clientStatuses: DOUBLE_SHIPMENT_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
    issueStatuses: ISSUE_STATUS_DOUBLE_SHIPMENT_REF_TRANSPORT_MAPPER
  },
  [ISSUE_SUB_TYPE_BROKEN_ORDER_ID]: {
    clientStatuses: BROKEN_ORDER_CROSSED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
    issueStatuses: ISSUE_STATUS_BROKEN_ORDER_REF_TRANSPORT_MAPPER
  },
  [ISSUE_SUB_TYPE_CROSSED_ORDER_ID]: {
    clientStatuses: CROSSED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
    issueStatuses: ISSUE_STATUS_CROSSED_ORDER_REF_TRANSPORT_MAPPER
  },
  [ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID]: {
    clientStatuses: POORLY_PREPARED_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
    issueStatuses: ISSUE_STATUS_POORLY_PREPARED_ORDERS_REF_TRANSPORT_MAPPER
  },
  [ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID]: {
    clientStatuses: UNTRACEABLE_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER,
    issueStatuses: ISSUE_STATUS_UNTRACEABLE_ORDER__REF_TRANSPORT_MAPPER
  },
  [ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID]: {
    clientStatuses: UNDELIVERED_ORDER_CLIENT_STATUSES_REF_TRANSPORT_MAPPER
  }
}

export function getConfigForIssueType (issueTypeId: IssueTypeIdTypes) {
  return ISSUE_CONFIG[issueTypeId] || {}
}

export function getConfigForClientStatusType (subIssueTypeId: SubIssueTypeIdTypes) {
  return CLIENT_ISSUE_STATUS_ISSUE_CONFIG[subIssueTypeId] || {}
}

export const BUSINESS_STATUS_MAPPER: BusinessStatusMapper = {
  [BUSINESS_STATUS_REQUESTED_ID]: BUSINESS_STATUS_REQUESTED_TEXT,
  [BUSINESS_STATUS_LABELLED_ID]: BUSINESS_STATUS_LABELLED_TEXT,
  [BUSINESS_STATUS_RECEIVED_ID]: BUSINESS_STATUS_RECEIVED_TEXT,
  [BUSINESS_STATUS_SENT_ID]: BUSINESS_STATUS_SENT_TEXT,
  [BUSINESS_STATUS_DELIVERED_ID]: BUSINESS_STATUS_DELIVERED_TEXT,
  [BUSINESS_STATUS_CANCELLED_ID]: BUSINESS_STATUS_CANCELLED_TEXT
}

export const ESTIMATED_COSTS_STATUS_MAPPER: EstimatedCostsStatusMapper = {
  [ADVERTISEMENT_STATUS_ID]: ADVERTISEMENT_STATUS_TEXT,
  [SENT_BUDGE_STATUS_ID]: SENT_BUDGE_STATUS_TEXT,
  [ACEPTED_STATUS_ID]: ACEPTED_STATUS_TEXT,
  [CANCELLED_STATUS_ID]: CANCELLED_STATUS_TEXT
}

export const DECREASE_TYPES_MAPPER: DecreaseTypesMapper = {
  [IVA_NOT_RECEIVED_ID]: IVA_NOT_RECEIVED_TEXT,
  [LOST_ORDERS_WITH_COMPENSATION_ID]: LOST_ORDERS_WITH_COMPENSATION_TEXT,
  [LOST_ORDERS_WITHOUT_COMPENSATION_ID]: LOST_ORDERS_WITHOUT_COMPENSATION_TEXT,
  [RETURN_COSTS_ID]: RETURN_COSTS_TEXT,
  [RETURN_REFUSED_ID]: RETURN_REFUSED_TEXT,
  [RETURN_WITH_COUPON_VOUCHER_ID]: RETURN_WITH_COUPON_VOUCHER_TEXT
}

export const SHIPMENT_METHOD_MAPPER: ShipmentMethodMapper = {
  [EXPRESS_SHIPMENT_METHOD_ID]: EXPRESS_SHIPMENT_METHOD_TEXT,
  [ESTANDAR_SHIPMENT_METHOD_ID]: ESTANDAR_SHIPMENT_METHOD_TEXT,
  [ECONOMY_SHIPMENT_METHOD_ID]: ECONOMY_SHIPMENT_METHOD_TEXT
}

export const PAYMENT_PURCHASE_METHOD_MAPPER: PaymentPurchaseMethodMapper = {
  [PAYMENT_TYPE_CARD_ID]: PAYMENT_TYPE_CARD,
  [PAYMENT_TYPE_BIZUM_ID]: PAYMENT_TYPE_BIZUM,
  [PAYMENT_TYPE_PAYPAL_ID]: PAYMENT_TYPE_PAYPAL,
  [PAYMENT_TYPE_TRANFER_ID]: PAYMENT_TYPE_TRANFER,
  [PAYMENT_TYPE_CARDSANTPA_ID]: PAYMENT_TYPE_CARDSANTPA,
  [PAYMENT_TYPE_CARDABANCAFER_ID]: PAYMENT_TYPE_CARDABANCAFER,
  [PAYMENT_TYPE_CARDABANCAOCTA_ID]: PAYMENT_TYPE_CARDABANCAOCTA,
  [PAYMENT_TYPE_CARDBBVAFER_ID]: PAYMENT_TYPE_CARDBBVAFER
}

export const ISSUE_LOCATION_MAPPER: IssueLocationMapper = {
  [CLIENT_ID]: CLIENT_TEXT,
  [OF_CANARY_ID]: OF_CANARY_TEXT,
  [SEND_TO_MADRID_ID]: SEND_TO_MADRID_TEXT,
  [MADRID_ID]: MADRID_TEXT,
  [SENT_SUPPLIER_ID]: SENT_SUPPLIER_TEXT
}

export const REFUNDS_STATUS_MAPPER: RefundStatusMapper = {
  [PENDING_REFUND_PAYMENT_STATUS_ID]: PENDING_REFUND_PAYMENT_STATUS_TEXT,
  [ADMITTED_REFUND_STATUS_ID]: ADMITTED_REFUND_STATUS_TEXT,
  [REQUESTED_REFUND_STATUS_ID]: REQUESTED_REFUND_STATUS_TEXT
}
