import {
  IResourceComponentsProps,
  useTranslate
} from '@pankod/refine-core'
import { Tabs } from '@pankod/refine-antd'
import { PendingPurchases } from './pendingPurchases'
import { SentPurchases } from './sentPurchases'
import { definitions } from 'interfaces'
import { processDate } from 'utility'
import { PAYMENT_PURCHASE_METHOD_MAPPER } from 'utility/mapper'
import { PaymentPurchaseMethodId } from 'types/types'

const select = 'id, order_id(id, status(name), alias, delivered_date), purchase_id, transaction_id, tracking_id, iva_amount, purchase_amount, invoice_number, created_at, updated_at, invoice_file,file_url, deleted, deleted_by, marketplace_legal_address, marketplace_legal_name, marketplace_cif, marketplace_province, marketplace_postal_code, reviewed, invoice_date, is_purchase, iva_type, payment_method_id, updated_by, iva_amount'

export const PurchaseList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const dataToExport = (marketOrders: any) => {
    const preparedItems: { [x: string]: any }[] = []
    const idList = new Set()
    const renderedIdList = new Set()
    const purchaseIdList: { invoiceNumber: string, purchaseId: string }[] = []

    marketOrders?.forEach((marketOrder: definitions['market_orders']) => {
      idList.add((marketOrder?.order_id as any)?.id)
    })

    idList.forEach((id) => {
      const marketOrderOfOrder = marketOrders?.filter((x: any) => x.order_id.id === id) || []

      marketOrderOfOrder?.forEach((marketOrder: definitions['market_orders']) => {
        purchaseIdList.push({ invoiceNumber: marketOrder.invoice_number || '', purchaseId: marketOrder.purchase_id || '' })
      })

      purchaseIdList.forEach((pIdItem: { invoiceNumber: string, purchaseId: string }) => {
        const mo = marketOrderOfOrder?.filter((x: any) => x.purchase_id === pIdItem.purchaseId)?.sort((a: any, b: any) => b.iva_type.localeCompare(a.iva_type))
        if (mo && mo.length > 0 && !renderedIdList.has(mo[0].purchase_id)) {
          renderedIdList.add(mo[0].purchase_id)
          // let haveSameInvoice = true
          // let sameType = true
          // const invNumber = mo[0].invoice_number
          // const purchaseType = mo[0].is_purchase

          // mo.forEach((marketOrder: definitions['market_orders']) => {
          //   if (marketOrder.invoice_number !== invNumber) haveSameInvoice = false
          // })
          // mo.forEach((marketOrder: definitions['market_orders']) => {
          //   if (marketOrder.is_purchase !== purchaseType) sameType = false
          // })

          // let totalInvoice = mo[0].purchase_amount
          // if (haveSameInvoice) totalInvoice = mo.map((marketOrder: definitions['market_orders']) => marketOrder.purchase_amount || 0).reduce((a: number, b: number) => a + b, 0)

          mo.forEach((marketOrder: definitions['market_orders'], index: number) => {
            const row = {
              [t('purchases.fields.invoiceDate')]: marketOrder?.invoice_date ? processDate(marketOrder?.invoice_date) : '',
              [t('purchases.fields.purchaseId')]: marketOrder?.purchase_id || '',
              [t('purchases.fields.invoiceNumber')]: marketOrder?.invoice_number || '',
              [t('purchases.fields.trackingId')]: marketOrder?.tracking_id || '',
              [t('market_order.fields.type')]: marketOrder.is_purchase ? t('market_order.fields.purchase') : t('market_order.fields.refund'),
              [t('order.fields.id')]: (marketOrder?.order_id as any)?.id || '',
              [t('order.fields.alias')]: (marketOrder?.order_id as any)?.alias || '',
              // [t('order.fields.status')]: (marketOrder?.order_id as any)?.status.name || '',
              [t('purchases.fields.marketplace')]: marketOrder?.marketplace_legal_name || '',
              [t('purchases.fields.cif')]: marketOrder?.marketplace_cif || '',
              [t('purchases.fields.province')]: marketOrder?.marketplace_province || '',
              [t('purchases.fields.postalCode')]: marketOrder?.marketplace_postal_code || '',
              [t('purchases.fields.baseAmount')]: ((marketOrder.purchase_amount || 0) - (marketOrder?.iva_amount || 0)) || '',
              [t('purchases.fields.ivaType')]: marketOrder?.iva_type || '',
              [t('purchases.fields.ivaAmount')]: marketOrder?.iva_amount || 0,
              [t('purchases.fields.totalInvoice')]: marketOrder.purchase_amount || 0,
              [t('invoices_matchings.fields.payment_method_id')]: t(`invoices_matchings.fields.payment_type.${PAYMENT_PURCHASE_METHOD_MAPPER[marketOrder?.payment_method_id as PaymentPurchaseMethodId || 1]}`) || ''
              // [t('purchases.fields.totalInvoice')]: haveSameInvoice ? totalInvoice || 0 : marketOrder.purchase_amount
              // [t('purchases.fields.delivered_date')]: ((marketOrder?.order_id as any)?.delivered_date) ? new Date(Date.parse((marketOrder?.order_id as any)?.delivered_date)) : ''
            }
            // if (index > 0) {
            //   if (haveSameInvoice || marketOrder.invoice_number === '') {
            //     delete row[t('purchases.fields.invoiceDate')]
            //     delete row[t('order.fields.alias')]
            //     delete row[t('purchases.fields.marketplace')]
            //     delete row[t('purchases.fields.cif')]
            //     delete row[t('purchases.fields.province')]
            //     delete row[t('purchases.fields.postalCode')]
            //     delete row[t('purchases.fields.totalInvoice')]

            //     if (sameType) delete row[t('market_order.fields.type')]
            //   }
            // }
            preparedItems.push(row)
          })
        }
      })
    })
    return preparedItems
  }

  const dataToExportSameInvoice = (marketOrders: any) => {
    const preparedItems: { [x: string]: any }[] = []
    const idList = new Set()
    const renderedIdList = new Set()
    const purchaseIdList: { invoiceNumber: string, purchaseId: string }[] = []

    marketOrders?.forEach((marketOrder: definitions['market_orders']) => {
      idList.add((marketOrder?.order_id as any)?.id)
    })

    idList.forEach((id) => {
      const marketOrderOfOrder = marketOrders?.filter((x: any) => x.order_id.id === id) || []

      marketOrderOfOrder?.forEach((marketOrder: definitions['market_orders']) => {
        purchaseIdList.push({ invoiceNumber: marketOrder.invoice_number || '', purchaseId: marketOrder.purchase_id || '' })
      })

      purchaseIdList.forEach((pIdItem: { invoiceNumber: string, purchaseId: string }) => {
        const mo = marketOrderOfOrder?.filter((x: any) => x.purchase_id === pIdItem.purchaseId && x.invoice_number === pIdItem.invoiceNumber && x.invoice_number !== '')?.sort((a: any, b: any) => b.iva_type.localeCompare(a.iva_type))
        if (mo && mo.length > 1 && !renderedIdList.has(mo[0].purchase_id)) {
          renderedIdList.add(mo[0].purchase_id)
          let haveSameInvoice = true
          let sameType = true
          const invNumber = mo[0].invoice_number
          const purchaseType = mo[0].is_purchase

          mo.forEach((marketOrder: definitions['market_orders']) => {
            if (marketOrder.invoice_number !== invNumber) haveSameInvoice = false
          })
          mo.forEach((marketOrder: definitions['market_orders']) => {
            if (marketOrder.is_purchase !== purchaseType) sameType = false
          })

          let totalInvoice = mo[0].purchase_amount
          if (haveSameInvoice) totalInvoice = mo.map((marketOrder: definitions['market_orders']) => marketOrder.purchase_amount || 0).reduce((a: number, b: number) => a + b, 0)

          mo.forEach((marketOrder: definitions['market_orders'], index: number) => {
            const row = {
              [t('purchases.fields.invoiceDate')]: marketOrder?.invoice_date ? processDate(marketOrder?.invoice_date) : '',
              [t('purchases.fields.purchaseId')]: marketOrder?.purchase_id || '',
              [t('purchases.fields.invoiceNumber')]: marketOrder?.invoice_number || '',
              [t('purchases.fields.trackingId')]: marketOrder?.tracking_id || '',
              [t('market_order.fields.type')]: marketOrder.is_purchase ? t('market_order.fields.purchase') : t('market_order.fields.refund'),
              [t('order.fields.id')]: (marketOrder?.order_id as any)?.id || '',
              [t('order.fields.alias')]: (marketOrder?.order_id as any)?.alias || '',
              // [t('order.fields.status')]: (marketOrder?.order_id as any)?.status.name || '',
              [t('purchases.fields.marketplace')]: marketOrder?.marketplace_legal_name || '',
              [t('purchases.fields.cif')]: marketOrder?.marketplace_cif || '',
              [t('purchases.fields.province')]: marketOrder?.marketplace_province || '',
              [t('purchases.fields.postalCode')]: marketOrder?.marketplace_postal_code || '',
              [t('purchases.fields.baseAmount')]: ((marketOrder.purchase_amount || 0) - (marketOrder?.iva_amount || 0)) || '',
              [t('purchases.fields.ivaType')]: marketOrder?.iva_type || '',
              [t('purchases.fields.ivaAmount')]: marketOrder?.iva_amount || 0,
              [t('purchases.fields.subtotal')]: marketOrder.purchase_amount || 0,
              [t('purchases.fields.totalInvoice')]: haveSameInvoice ? totalInvoice || 0 : marketOrder.purchase_amount,
              [t('invoices_matchings.fields.payment_method_id')]: t(`invoices_matchings.fields.payment_type.${PAYMENT_PURCHASE_METHOD_MAPPER[marketOrder?.payment_method_id as PaymentPurchaseMethodId || 1]}`) || ''
              // [t('purchases.fields.delivered_date')]: ((marketOrder?.order_id as any)?.delivered_date) ? new Date(Date.parse((marketOrder?.order_id as any)?.delivered_date)) : ''
            }
            if (index > 0) {
              if (haveSameInvoice && marketOrder.invoice_number !== '') {
                delete row[t('order.fields.alias')]
                delete row[t('purchases.fields.marketplace')]
                delete row[t('purchases.fields.cif')]
                delete row[t('purchases.fields.province')]
                delete row[t('purchases.fields.postalCode')]
                delete row[t('purchases.fields.totalInvoice')]

                if (sameType) delete row[t('market_order.fields.type')]
              }
            }
            preparedItems.push(row)
          })
        }
      })
    })
    return preparedItems
  }

  return (
    <Tabs defaultActiveKey='1' size='middle'>
      <Tabs.TabPane tab={t('purchases.pendingPurchases')} key='1'>
        <PendingPurchases
          select={select}
          dataToExport={dataToExport}
          dataToExportSameInvoice={dataToExportSameInvoice}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('purchases.sentPurchases')} key='2'>
        <SentPurchases
          select={select}
          dataToExport={dataToExport}
          dataToExportSameInvoice={dataToExportSameInvoice}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}
