import { Card, Divider, Space, Typography } from '@pankod/refine-antd'
import { useTranslate } from '@pankod/refine-core'
import { PendingMarketOrders } from 'interfaces'
import { currency } from 'utility'

type Props = {
  items: PendingMarketOrders['orders']['items']
}

export const ItemsInfo: React.FC<Props> = ({ items }) => {
  const t = useTranslate()

  return (
    <Card bordered={false} style={{ width: '100%' }}>
      <div>
        <Typography.Title level={4}>
          {t('invoices_matchings.titles.items_info')}
        </Typography.Title>
      </div>

      <div style={{ marginTop: '1rem', display: 'flex', gap: 30, overflowX: 'auto' }}>
        {items?.map((item, index) => {
          const { name, price, iva, syniva_price: synivaPrice } = item
          return (
            <>
              <Space key={item.id} direction='vertical' style={{ minWidth: 200 }}>
                <Typography.Text>
                  <b>{t('item.fields.name')}</b>:{' '}
                  {name}
                </Typography.Text>
                <Typography.Text>
                  <b>{t('item.fields.price')}</b>:{' '}
                  {currency(price)}
                </Typography.Text>
                <Typography.Text>
                  <b>{t('item.fields.iva')}</b>:{' '}
                  {iva}
                </Typography.Text>
                <Typography.Text>
                  <b>{t('item.fields.syniva_price')}</b>:{' '}
                  {currency(synivaPrice)}
                </Typography.Text>
              </Space>
              {index !== items?.length - 1 && <Divider type='vertical' orientation='right' style={{ height: 120 }} />}
            </>
          )
        })}
      </div>
    </Card>
  )
}
