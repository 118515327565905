import { Typography, Space, Icons, DateField, Card, Grid, Row, Col, Button } from '@pankod/refine-antd'
import { useNavigation } from '@pankod/refine-core'
import { getShipmentData } from 'utility/supabaseApi'
import { definitions } from 'interfaces'
import {
  ADMITTED_STATUS,
  CANCELLED_STATUS,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DELIVERED_STATUS,
  ORDER_STATUS_ID_ON_HOLD,
  ORDER_STATUS_ID_PENDING_PAYMENT,
  ORDER_STATUS_ID_REQUESTED,
  PARCIAL_RETURN_STATUS,
  PENALIZATION_STATUS,
  PREPARED_STATUS,
  PROCESSED_STATUS,
  RETURN_STATUS,
  SUPPLEMENT_STATUS,
  TIPSA_AGENCY_CODE,
  TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED,
  TIPSA_AGENCY_SERVICE_TYPE_DIRECT
} from 'utility/constants'
import { AddressInfo } from './address_info'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { currency, getGender, getLastOrder } from 'utility'

const { useBreakpoint } = Grid

type UserAddressesProps = {
  order: definitions['Order'] | undefined;
  canEdit?: boolean
};

export const getInternalShipmentTotal = (userOrders: any) => {
  if (!userOrders) return 0

  const total = userOrders.filter((o: any) => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc: any, curr: any) => {
    const { shipping_price: shippingPrice, marketplace_id: marketplaceId, syniva_price: synivaPrice, items } = curr

    let shipmentCost = 0
    if (marketplaceId.id !== 0) {
      if (synivaPrice > (marketplaceId.free_shipment_price || 0)) {
        shipmentCost = 0
      } else {
        shipmentCost = marketplaceId.shipment_price || 0
      }
    } else {
      if (items && items.length > 0) {
        shipmentCost = items?.reduce((prev: any, curr: any) => (prev.shipping_price_to_madrid || 0) > (curr.shipping_price_to_madrid || 0) ? prev : curr)?.shipping_price_to_madrid || 0
      }
    }

    return acc + (shippingPrice - shipmentCost)
  }, 0)

  return total
}

export const UserAddresses: React.FC<UserAddressesProps> = (props) => {
  const { t } = useTranslation()
  const { xl } = useBreakpoint()
  const { show } = useNavigation()

  const [order, setOrder] = useState(props?.order)
  const [modifiedAddress, setModifiedAddress] = useState(Date.now)

  const [shipmentData, setShipmentData] = useState({
    delivery_note: '',
    tracking_url: null,
    tracking_id: null,
    shipper_type: '',
    type: '',
    id: null
  })

  const shipmentLink = useRef(null)

  const retrieveShipment = async () => {
    setShipmentData(await getShipmentData(order?.id || null))
  }

  const openLink = () => {
    const url = shipmentData?.tracking_url || ''
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (!shipmentData?.id) {
      retrieveShipment()
    }
  }, [])

  const showShipmentData = () => {
    if (shipmentData?.shipper_type?.toLowerCase().includes('tipsa') && shipmentData?.tracking_url) {
      return <>
        <Button ref={shipmentLink} icon={<Icons.InfoCircleOutlined />} onClick={() => openLink()}>
          {t('order.fields.shipmentDataAvailable')}
        </Button>
        {shipmentData?.delivery_note && <Typography.Text>
          <strong>{t('order.fields.shipmentDataAvailable')}: </strong> {TIPSA_AGENCY_CODE}{(shipmentData?.type.toLowerCase().includes('directo') ? TIPSA_AGENCY_SERVICE_TYPE_DIRECT : TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED)}{shipmentData?.delivery_note}
        </Typography.Text>}
      </>
    } else if (shipmentData?.shipper_type?.toLowerCase().includes('correos')) {
      return <Typography.Text>
        <strong>{t('order.fields.shipmentDataAvailable')}: </strong> {shipmentData?.tracking_id}
      </Typography.Text>
    } else if (shipmentData?.shipper_type?.toLowerCase().includes('ontime')) {
      return <Typography.Text>
        <strong>{t('order.fields.shipmentDataAvailable')}: </strong> {shipmentData?.tracking_url}
      </Typography.Text>
    } else if (shipmentData?.shipper_type?.toLowerCase().includes('ctt')) {
      return <Typography.Text>
        <strong>{t('order.fields.shipmentDataAvailable')}: </strong> {shipmentData?.tracking_url}
      </Typography.Text>
    }
    return null
  }

  const updateAddressInfo = (addressData: any, addressType: String) => {
    if (order) {
      const orderToUpdate = order
      if (addressType === 'shipping') {
        orderToUpdate.shipping_address = addressData
        orderToUpdate.shipping_address_id = addressData.id
      } else {
        orderToUpdate.billing_address = addressData
        orderToUpdate.billing_address_id = addressData.id
      }

      setModifiedAddress(Date.now)
      setOrder(orderToUpdate)
    }
  }

  const Text: React.FC<{ title: string; children: ReactNode; }> = ({ title, children }) => {
    return (
      <Typography.Text>
        <span style={{ fontWeight: 'bold' }}>{`${t(title)}: `}</span>
        {children}
      </Typography.Text>
    )
  }

  const internalShipmentTotal = getInternalShipmentTotal(order?.user.orders)
  const supplementsTotal = order?.user.orders?.filter(o => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id))
    .map(o => {
      const sups = o.supplements.filter(s => !s.deleted).filter(s => s.volumetric)
      return sups.reduce((acc, curr) => acc + (curr.supplement || 0), 0)
    })
    .reduce((acc, curr) => acc + curr, 0) || 0
  const insuranceTotal = order?.user.orders?.filter(o => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc, curr) => acc + (curr.shipping_insurance_price || 0), 0) || 0
  const customOrdersTotal = order?.user.orders?.filter(o => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc, curr) => acc + curr.custom_order_price, 0) || 0
  const internalTotal = internalShipmentTotal + supplementsTotal + insuranceTotal + customOrdersTotal

  return (
    <Card bordered={false} style={{ height: '100%' }}>
      <Space direction="vertical" style={{ width: '100%', height: '100%' }}>
        <Space
          direction="vertical"
          style={{
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Typography.Title
            level={3}
            style={{ cursor: 'pointer', color: 'darkblue', textAlign: 'left', width: 'fit-content', wordBreak: 'break-word' }}
            onClick={() => show('users', order?.user_id || 0)}
          >
            {order?.user?.name}
          </Typography.Title>
        </Space>
        <Space
          direction="vertical"
          style={{
            width: '100%',
            textAlign: xl ? 'unset' : 'center'
          }}
        >
          <Row style={{ gap: 4, justifyContent: 'space-between' }}>
            <Col lg={24} xl={7} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text title="users.email">
                {order?.user?.email}
              </Text>
              <Text title="users.created_at">
                <DateField format={DEFAULT_DATE_TIME_FORMAT} value={order?.user.created_at} />
              </Text>
              <Text title="users.birthday">
                {(order?.user.birthday) ? <DateField format={DEFAULT_DATE_FORMAT} value={order?.user.birthday} /> : t('common.no_specified')}
              </Text>
              <Text title="users.gender">
                {t(getGender(order?.user.gender))}
              </Text>
              <Text title="users.island">
                {order?.shipping_address.island.name}
              </Text>
            </Col>
            <Col lg={24} xl={8} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text title="users.orders">
                {order?.user.orders?.filter(o => o.status_id !== ORDER_STATUS_ID_PENDING_PAYMENT).length}
              </Text>
              <Text title="users.ordersInProgress">
                {order?.user.orders?.filter(o => o.status_id && [ORDER_STATUS_ID_REQUESTED, PROCESSED_STATUS, SUPPLEMENT_STATUS, ADMITTED_STATUS, PREPARED_STATUS, ORDER_STATUS_ID_ON_HOLD].includes(o.status_id)).length}
              </Text>
              <Text title="users.delivered_orders">
                {order?.user.orders?.filter(o => o.status_id === DELIVERED_STATUS).length}
              </Text>
              <Text title="users.cancelled_orders">
                {order?.user.orders?.filter(o => o.status_id === CANCELLED_STATUS).length}
              </Text>
              <Text title="users.totalReturns">
                {order?.user.orders?.filter(o => o.status_id === RETURN_STATUS).length}
              </Text>
              <Text title="users.partialReturns">
                {order?.user.orders?.filter(o => o.status_id === PARCIAL_RETURN_STATUS).length}
              </Text>
              <Text title="users.penalization_orders">
                {order?.user.orders?.filter(o => o.status_id === PENALIZATION_STATUS).length}
              </Text>
              <Text title="order.pendingPaymentOrders">
                {order?.user.orders?.filter(o => o.status_id === ORDER_STATUS_ID_PENDING_PAYMENT).length}
              </Text>
              <Text title="users.last_order_date">
                {order?.user?.orders && order?.user?.orders.length ? getLastOrder(order?.user?.orders, -1) : ''}
              </Text>
              <Text title="users.prev_last_order_date">
                {order?.user?.orders && order?.user?.orders.length ? getLastOrder(order?.user?.orders, -2) : ''}
              </Text>
            </Col>
            <Col lg={24} xl={7} style={{ display: 'flex', flexDirection: 'column' }}>
              <Text title="users.total">
                {currency(order?.user.orders?.filter(o => o.status_id && ![ORDER_STATUS_ID_PENDING_PAYMENT, CANCELLED_STATUS, RETURN_STATUS].includes(o.status_id)).reduce((acc, curr) => acc + curr.total, 0))}
              </Text>
              <Text title="users.internal_shipment_total">
                {currency(internalShipmentTotal)}
              </Text>
              <Text title="users.supplements_total">
                {currency(supplementsTotal)}
              </Text>
              <Text title="users.insurance_total">
                {currency(insuranceTotal)}
              </Text>
              <Text title="users.custom_orders_total">
                {currency(customOrdersTotal)}
              </Text>
              <Text title="users.internal_total">
                {currency(internalTotal)}
              </Text>
            </Col>
          </Row>
        </Space>
      </Space>

      <Row>
        <Col xl={12} lg={24} xs={24} id={String(modifiedAddress)}>
          <AddressInfo
            orderId={order?.id}
            address={(order?.shipping_address_id && order?.shipping_address)
              ? { ...order?.shipping_address, id: Number(order?.shipping_address_id) }
              : order?.shipping_address}
            title={t('order.fields.shippingAddress')}
            type={'shipping'}
            updateAddressInfo={updateAddressInfo}
            canEdit={props.canEdit}
          />
        </Col>
        <Col xl={12} lg={24} xs={24} id={String(modifiedAddress)}>
          <AddressInfo
            orderId={order?.id}
            address={(order?.billing_address_id && order?.billing_address)
              ? { ...order?.billing_address, id: Number(order?.billing_address_id) }
              : order?.billing_address}
            title={t('order.fields.billingAddress')}
            type={'billing'}
            updateAddressInfo={updateAddressInfo}
            canEdit={props.canEdit}
          />
        </Col>
      </Row>

      <Space direction="vertical" style={{ width: '100%', height: '100%', paddingTop: '30px' }}>
        {showShipmentData()}
      </Space>

    </Card>
  )
}
