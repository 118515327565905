import { IconByExt } from './IconByExt'
// import { PdfViewer } from 'components/PdfViewer/PdfViewer'
import { PreviewMask } from './PreviewMask'
import { getSignedUrl } from 'utility'

type FileWithLinkProps = {
  href: string
  pdfPreview?: boolean
}

export const FileWithLink: React.FC<FileWithLinkProps> = ({ href, pdfPreview = true }) => {
  const parts = href.split('.')
  const ext = parts[parts.length - 1]

  const signedUrl = (href: string) => {
    return <div className="ant-image" style={{ width: 64, height: 64, alignContent: 'center' }}>
      <IconByExt ext={ext} className="ant-image-img" style={{ fontSize: 40 }} />
      <button
        onClick={async () => {
          const url = await getSignedUrl(href, true)
          window.open(url || '', '_blank', 'noopener,noreferrer')
        }}
        aria-label="eye"
        className="anticon">
        <PreviewMask label="actions.open" />
      </button>
    </div>
  }

  return signedUrl(href)

  // if (href.endsWith('.png') || href.endsWith('.jpg')) {
  //   return signedUrl(href, true)
  // } else {
  // }
}
