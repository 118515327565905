import { useTranslate } from '@pankod/refine-core'

import {
  Drawer,
  DrawerProps,
  Form,
  FormProps,
  Input,
  Grid,
  Edit,
  Upload,
  RcFile,
  Col,
  Row,
  Typography,
  DatePicker,
  Radio,
  Divider,
  useModal,
  Select
} from '@pankod/refine-antd'
import { supabaseClient } from 'utility/supabaseClient'
import { normalizeFile } from 'utility/normalize'
import dayjs from 'dayjs'
import moment from 'moment'
import { SupplierFinder } from './supplierFinder'
import { useState } from 'react'
import { InvoicesForm } from './invoicesForm'
import { AdviceModal } from 'components/AdviceModal/AdviceModal'
import { PAYMENT_TYPE_BIZUM, PAYMENT_TYPE_CARD, PAYMENT_TYPE_PAYPAL, PAYMENT_TYPE_TRANFER, PAYMENT_TYPE_CARDABANCAFER, PAYMENT_TYPE_CARDABANCAOCTA, PAYMENT_TYPE_CARDBBVAFER, PAYMENT_TYPE_CARDSANTPA } from 'utility'

type EditMarketOrderProps = {
  drawerProps: DrawerProps
  formProps: FormProps
  closeDrawer: any
  tableQueryResult: any
  relatedPurchases: any[]
  defaultMarketplace: any
  order: any
  expectedMarketOrderPrice: number
  marketplaceShipmentPrice: number
}

export const EditMarketOrder: React.FC<EditMarketOrderProps> = ({
  drawerProps,
  formProps,
  closeDrawer,
  tableQueryResult,
  relatedPurchases,
  defaultMarketplace,
  order,
  expectedMarketOrderPrice,
  marketplaceShipmentPrice
}) => {
  const t = useTranslate()
  const breakpoint = Grid.useBreakpoint()

  const { modalProps, show, close } = useModal()

  const updateOrderAfterUpdate = async () => {
    const orderId = order?.id
    if (orderId) {
      const { error: errorOrder } = await supabaseClient
        .from('orders')
        .update({
          updated_at: moment(),
          updated_by: supabaseClient.auth.user()?.email
        })
        .eq('id', orderId)

      if (errorOrder) {
        console.log(errorOrder)
      }
    }
  }

  const updateMarketplaceData = (item: any) => {
    const fData = { ...formData }
    if (fData.form) {
      fData.form.setFields([
        {
          name: ['marketplace_cif'],
          value: item.cif
        },
        {
          name: ['marketplace_legal_name'],
          value: item.legal_name
        },
        {
          name: ['marketplace_legal_address'],
          value: item.legal_address
        },
        {
          name: ['marketplace_province'],
          value: item.province
        },
        {
          name: ['marketplace_postal_code'],
          value: item.postal_code
        }
      ])

      setFormData({ ...fData })
    }
  }

  const [formData, setFormData] = useState({ ...formProps })
  const [invoices, setInvoices] = useState<any>(Array.from(new Array(relatedPurchases.length || 1)))
  const [paymentType, setPaymentType] = useState(formProps?.initialValues?.payment_method_id || 1)

  const [validationErrorPurchaseId, setValidationErrorPurchaseId] = useState(false)

  const SaveSupplier = async () => {
    if (formData?.form?.getFieldValue('marketplace_cif')) {
      const { data: suppliers } = await supabaseClient
        .from('suppliers')
        .select('cif')
        .eq('cif', formData?.form?.getFieldValue('marketplace_cif'))

      if (suppliers && suppliers.length === 0) {
        const supplier = {
          cif: formData?.form?.getFieldValue('marketplace_cif'),
          legal_name: formData?.form?.getFieldValue('marketplace_legal_name'),
          legal_address: formData?.form?.getFieldValue('marketplace_legal_address'),
          country: 'Spain',
          province: formData?.form?.getFieldValue('marketplace_province'),
          postal_code: formData?.form?.getFieldValue('marketplace_postal_code')
        }
        await supabaseClient.from('suppliers').insert(supplier)
      }
    }
  }

  const validateData = () => {
    return (formData.form?.getFieldValue('purchase_id') !== null && formData.form?.getFieldValue('purchase_id') !== undefined && formData.form?.getFieldValue('purchase_id') !== '')
  }

  const onClickButton = async () => {
    setValidationErrorPurchaseId(false)
    if (!validateData()) {
      setValidationErrorPurchaseId(true)
      return
    }
    SaveSupplier()
    // if (saveButtonProps && saveButtonProps.onClick) saveButtonProps?.onClick(e)

    // if is in related and not on invoice, remove it
    for (const rp of relatedPurchases) {
      if (!invoices.find((x: any) => x.id === rp.id)) {
        await supabaseClient.from('market_orders').update({
          deleted: true,
          updated_by: supabaseClient.auth.user()?.email
        }).eq('id', rp.id)
      }
    }

    // Update using invoice array
    for (const [index, value] of invoices.entries()) {
      const dataToSave = {
        order_id: order?.id,
        purchase_id: formData.form?.getFieldValue('purchase_id').trim(),
        transaction_id: formData.form?.getFieldValue('transaction_id')?.trim() || '',
        tracking_id: formData.form?.getFieldValue('tracking_id')?.trim() || '',
        invoice_number: formData.form?.getFieldValue('invoice_number')?.trim(),
        invoice_file: (index === 0) ? formData.form?.getFieldValue('invoice_file') || '' : '',
        // file_url: (index === 0) ? formData.form?.getFieldValue('file_url') : null,
        marketplace_legal_name: formData.form?.getFieldValue('marketplace_legal_name') || null,
        marketplace_legal_address: formData.form?.getFieldValue('marketplace_legal_address') || null,
        marketplace_cif: formData.form?.getFieldValue('marketplace_cif') || null,
        marketplace_province: formData.form?.getFieldValue('marketplace_province') || null,
        marketplace_postal_code: formData.form?.getFieldValue('marketplace_postal_code') || null,
        invoice_date: formData.form?.getFieldValue('invoice_date') || null,
        is_purchase: formData.form?.getFieldValue('is_purchase'),
        purchase_amount: value.purchase_amount,
        iva_type: value.iva_type,
        iva_amount: value.iva_amount,
        purchase_parent: (index === 0),
        updated_by: supabaseClient.auth.user()?.email,
        payment_method_id: paymentType
      }

      if (value.id !== undefined) {
        await supabaseClient.from('market_orders').update(dataToSave).eq('id', value.id)
      } else {
        await supabaseClient.from('market_orders').insert(dataToSave)
      }
    }

    updateOrderAfterUpdate()

    await tableQueryResult()
    window.location.reload()
    closeDrawer()
  }

  return (
    <Drawer
      {...drawerProps}
      width={breakpoint.sm ? '600px' : '100%'}
      bodyStyle={{ padding: 0 }}
      zIndex={700}
    >
      <Edit
        resource="market_orders"
        saveButtonProps={{
          onClick: async () => {
            const expected = expectedMarketOrderPrice + marketplaceShipmentPrice
            // eslint-disable-next-line camelcase
            const invoicePrice = invoices.map((invoice: { purchase_amount: number }) => invoice.purchase_amount || 0).reduce((acc: number, current: number) => acc + current, 0)

            if (expected < invoicePrice) show()
            else await onClickButton()
          }
        }}
        breadcrumb={false}
        goBack={false}
        title={t('order.actions.editMarketOrder')}
        headerButtons={() => (<></>)}
      >
        <Form {...formProps} layout="vertical">
          <Form.Item
            hidden={true}
            name="order_id"
          >
            <Input />
          </Form.Item>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('market_order.fields.purchaseId')}
                name="purchase_id"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('market_order.fields.invoiceNumber')}
                name="invoice_number"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('market_order.fields.transactionId')}
            name="transaction_id"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('market_order.fields.trackingId')}
            name="tracking_id"
          >
            <Input />
          </Form.Item>

          <Divider />

          <InvoicesForm edit={true} relatedPurchases={relatedPurchases} setInvoicesToAdd={setInvoices} />

          <Row style={{ marginTop: '2rem' }} gutter={[64, 0]} wrap>
            <Col xs={24}>
              <Form.Item label={t('invoices_matchings.fields.payment_method_id')} name="payment_method_id">
                <Select
                  value={paymentType}
                  options={[
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARD}`), value: 1 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDSANTPA}`), value: 5 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDABANCAFER}`), value: 6 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDABANCAOCTA}`), value: 7 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_CARDBBVAFER}`), value: 8 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_BIZUM}`), value: 2 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_PAYPAL}`), value: 3 },
                    { label: t(`invoices_matchings.fields.payment_type.${PAYMENT_TYPE_TRANFER}`), value: 4 }
                  ]}
                  onChange={(e) => setPaymentType(e)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[64, 0]} wrap>
            <Col xs={24}>
              <Form.Item label="Archivos">
                <Form.Item
                  name="invoice_file"
                  valuePropName={(formProps?.initialValues?.invoice_file !== null && formProps?.initialValues?.invoice_file !== '') ? 'fileList' : undefined}
                  // getValueFromEvent={normalizeFile}
                  normalize={normalizeFile}
                  noStyle
                >
                  <Upload.Dragger
                    name="invoice_file"
                    listType='picture'
                    customRequest={async ({
                      file,
                      onError,
                      onSuccess
                    }) => {
                      const rcFile = file as RcFile
                      const fileUrl = `${rcFile.name}`

                      const { error } = await supabaseClient.storage
                        .from('files')
                        .upload(fileUrl, file, {
                          cacheControl: '3600',
                          upsert: true
                        })

                      if (error) {
                        return onError?.(error)
                      }
                      const { signedURL, error: urlError } = await supabaseClient.storage
                        .from('files').createSignedUrl(fileUrl, 60)

                      if (urlError) {
                        return onError?.(urlError)
                      }

                      onSuccess?.(
                        { url: signedURL },
                        new XMLHttpRequest()
                      )
                    }}
                  >
                    <p className="ant-upload-text">
                      {t('actions.dragFile')}
                    </p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('market_order.fields.invoiceDate')}
                name='invoice_date'
                getValueProps={(value) => {
                  if (value) {
                    const date = new Date(Date.parse(value))
                    date.setHours(5)
                    return { value: date ? dayjs(date) : '' }
                  }
                  return { value: '' }
                }}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                name="is_purchase"
                label={t('market_order.fields.type')}
                style={{ margin: 0 }}
              >
                <Radio.Group>
                  <Radio value={true}>{t('market_order.fields.purchase')}</Radio>
                  <Radio value={false}>{t('market_order.fields.refund')}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={4}>{t('market_order.marketplaceData')}</Typography.Title>

          <SupplierFinder updateMarketplaceData={updateMarketplaceData} />

          <Row gutter={[64, 0]} wrap>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('marketplace.fields.CIF')}
                name='marketplace_cif'
                initialValue={defaultMarketplace?.cif}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('marketplace.fields.legalName')}
                name='marketplace_legal_name'
                initialValue={defaultMarketplace?.legal_name}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('marketplace.fields.legalAddress')}
                name='marketplace_legal_address'
                initialValue={defaultMarketplace?.legal_address}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label={t('marketplace.fields.province')}
                name='marketplace_province'
                initialValue={defaultMarketplace?.province}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('marketplace.fields.postalCode')}
                name='marketplace_postal_code'
                initialValue={defaultMarketplace?.postal_code}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {(validationErrorPurchaseId) ? <Typography.Text>{t('market_order.validation.errorValidation')}</Typography.Text> : null}
          <AdviceModal
            modalProps={modalProps}
            close={close}
            show={show}
            infoText={t('supplement.advise.purchaseLine')}
            okFunction={() => onClickButton()}
          />
        </Form>
      </Edit>
    </Drawer>
  )
}
